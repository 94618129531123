import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Form, Container, Row, Col, Table, Spinner } from "react-bootstrap";
import Footer from "../../../Components/Footer";
import TopHeader from "../../../Components/TopHeader";
import AdminHeader from "../../../Components/AdminHeader";
import Sidebar from "../../../Components/Sidebar";

const AppointmentsData = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentAppointmentId, setCurrentAppointmentId] = useState(null);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [notes, setNotes] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [state, setState] = useState("");
  const [variant, setVariant] = useState("");
  const [year, setYear] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    setLoading(true);
    try {
      const appointmentsCollection = collection(db, "appointments");
      const snapshot = await getDocs(appointmentsCollection);
      const appointmentsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAppointments(appointmentsData);
    } catch (error) {
      console.error("Error fetching appointments: ", error);
      toast.error("Failed to fetch appointments. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (appointmentId) => {
    const appointmentToEdit = appointments.find(
      (appointment) => appointment.id === appointmentId
    );
    if (appointmentToEdit) {
      setCurrentAppointmentId(appointmentId);
      setFullName(appointmentToEdit.contact.fullName || "not added");
      setEmail(appointmentToEdit.contact.email || "not added");
      setAppointmentDate(appointmentToEdit.appointment.appointmentDate || "not added");
      setAppointmentTime(appointmentToEdit.appointment.appointmentTime || "not added");
      setPhoneNumber(appointmentToEdit.appointment.PhoneNumber || "not added");
      setNotes(appointmentToEdit.contact.notes || "not added");
      setSelectedService(appointmentToEdit.services.selectedService || "not added");
      setMake(appointmentToEdit.vehicleDetails.make || "not added");
      setModel(appointmentToEdit.vehicleDetails.model || "not added");
      setRegistrationNumber(appointmentToEdit.vehicleDetails.registrationNumber || "not added");
      setState(appointmentToEdit.vehicleDetails.state || "not added");
      setVariant(appointmentToEdit.vehicleDetails.variant || "not added");
      setYear(appointmentToEdit.vehicleDetails.year || "not added");
      setShowModal(true);
    }
  };

  const handleDelete = async (appointmentId) => {
    setLoading(true);
    try {
      const appointmentRef = doc(db, "appointments", appointmentId);
      await deleteDoc(appointmentRef);
      toast.success("Appointment deleted successfully!");
      fetchAppointments(); // Refresh appointments after deletion
    } catch (error) {
      console.error("Error deleting appointment: ", error);
      toast.error("Failed to delete appointment. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (currentAppointmentId) {
        const appointmentData = {
          contact: { fullName, email, notes },
          appointment: { appointmentDate, appointmentTime, PhoneNumber: phoneNumber },
          services: { selectedService },
          vehicleDetails: { make, model, registrationNumber, state, variant, year },
        };

        const appointmentRef = doc(db, "appointments", currentAppointmentId);
        await updateDoc(appointmentRef, appointmentData);
        toast.success("Appointment updated successfully!");

        setCurrentAppointmentId(null);
        resetFormFields();
        fetchAppointments(); // Refresh appointments after update
        setShowModal(false);
      }
    } catch (error) {
      console.error("Error updating appointment: ", error);
      toast.error("Failed to update appointment. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const resetFormFields = () => {
    setFullName("");
    setEmail("");
    setAppointmentDate("");
    setAppointmentTime("");
    setPhoneNumber("");
    setNotes("");
    setSelectedService("");
    setMake("");
    setModel("");
    setRegistrationNumber("");
    setState("");
    setVariant("");
    setYear("");
  };

  return (
    <>
      <ToastContainer />
      <AdminHeader />
      <div className="container-fluid p-0">
        <div className="row">
          <Sidebar />
          <div className="col-md-9">
            <Container className="mt-5">
              <h2 className="mb-4 text-center">Appointments Data</h2>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
              ) : (
                <Table striped bordered hover responsive className="text-center">
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Appointment Date</th>
                      <th>Appointment Time</th>
                      <th>Phone Number</th>
                      <th>Notes</th>
                      <th>Selected Service</th>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Registration Number</th>
                      <th>State</th>
                      <th>Variant</th>
                      <th>Year</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {appointments.map((appointment) => (
                      <tr key={appointment.id}>
                        <td>{appointment.contact.fullName || "not added"}</td>
                        <td>{appointment.contact.email || "not added"}</td>
                        <td>{appointment.appointment.appointmentDate || "not added"}</td>
                        <td>{appointment.appointment.appointmentTime || "not added"}</td>
                        <td>{appointment.appointment.PhoneNumber || "not added"}</td>
                        <td>{appointment.contact.notes || "not added"}</td>
                        <td>{appointment.services.selectedService || "not added"}</td>
                        <td>{appointment.vehicleDetails.make || "not added"}</td>
                        <td>{appointment.vehicleDetails.model || "not added"}</td>
                        <td>{appointment.vehicleDetails.registrationNumber || "not added"}</td>
                        <td>{appointment.vehicleDetails.state || "not added"}</td>
                        <td>{appointment.vehicleDetails.variant || "not added"}</td>
                        <td>{appointment.vehicleDetails.year || "not added"}</td>
                        <td className="d-flex gap-1">
                          <Button
                            size="sm"
                            variant="primary"
                            className="me-2"
                            onClick={() => handleEdit(appointment.id)}
                            disabled={loading}
                          >
                            <i className="fa fa-edit"></i>
                          </Button>
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() => handleDelete(appointment.id)}
                            disabled={loading}
                          >
                            {loading ? "Deleting...." : <i className="fa fa-trash"></i>}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Container>
          </div>
        </div>
      </div>

      {/* Edit Appointment Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Appointment</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleUpdate}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="editFullName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editAppointmentDate">
              <Form.Label>Appointment Date</Form.Label>
              <Form.Control
                type="date"
                value={appointmentDate}
                onChange={(e) => setAppointmentDate(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editAppointmentTime">
              <Form.Label>Appointment Time</Form.Label>
              <Form.Control
                type="time"
                value={appointmentTime}
                onChange={(e) => setAppointmentTime(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editNotes">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                type="text"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editSelectedService">
              <Form.Label>Selected Service</Form.Label>
              <Form.Control
                type="text"
                value={selectedService}
                onChange={(e) => setSelectedService(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editMake">
              <Form.Label>Make</Form.Label>
              <Form.Control
                type="text"
                value={make}
                onChange={(e) => setMake(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editModel">
              <Form.Label>Model</Form.Label>
              <Form.Control
                type="text"
                value={model}
                onChange={(e) => setModel(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editRegistrationNumber">
              <Form.Label>Registration Number</Form.Label>
              <Form.Control
                type="text"
                value={registrationNumber}
                onChange={(e) => setRegistrationNumber(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editState">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editVariant">
              <Form.Label>Variant</Form.Label>
              <Form.Control
                type="text"
                value={variant}
                onChange={(e) => setVariant(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editYear">
              <Form.Label>Year</Form.Label>
              <Form.Control
                type="text"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? "Updating..." : "Update"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AppointmentsData;
