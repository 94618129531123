import React, { useState, useEffect } from "react";
import { db, storage } from "../../../firebase";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import {
  addDoc,
  collection,
  serverTimestamp,
  deleteDoc,
  doc,
  updateDoc,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import AdminHeader from "../../../Components/AdminHeader";
import Sidebar from "../../../Components/Sidebar";

const AddPost = () => {
  const [posts, setPosts] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [currentPostId, setCurrentPostId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    try {
      fetchPosts();
    } catch (error) {
      console.error("Error fetching posts: ", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPosts = async () => {
    const postsCollection = collection(db, "blogs");
    const snapshot = await getDocs(postsCollection);
    const postsData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setPosts(postsData);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleImageDelete = () => {
    setImage(null);
    setImagePreview(null);
  };

  const handleEdit = async (postId) => {
    setCurrentPostId(postId);
    const postToEdit = posts.find((post) => post.id === postId);
    if (postToEdit) {
      setTitle(postToEdit.title);
      setContent(postToEdit.content);
      setImagePreview(postToEdit.imageUrl || null);
      setShowModal(true);
    }
  };

  const handleDelete = async (postId, imageUrl) => {
    setDelLoading(true);
    try {
      const postRef = doc(db, "blogs", postId);
      const postDoc = await getDoc(postRef);
      if (!postDoc.exists()) {
        throw new Error("Document does not exist");
      }

      await deleteDoc(postRef);

      if (imageUrl) {
        const imageRef = ref(storage, imageUrl);
        await deleteObject(imageRef);
      }

      toast.success("Blog post deleted successfully!");
      fetchPosts();
    } catch (error) {
      console.error("Error deleting post: ", error);
      toast.error("Failed to delete post. Please try again later.");
    } finally {
      setDelLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let imageUrl = imagePreview; // Default to the existing image

    try {
      if (image) {
        const imageRef = ref(storage, `blog_images/${image.name}`);
        const uploadTask = uploadBytesResumable(imageRef, image);

        await uploadTask
          .then(async () => {
            imageUrl = await getDownloadURL(imageRef);
            toast.success("Image uploaded successfully");
          })
          .catch((error) => {
            console.error("Error uploading image: ", error);
            toast.error("Error uploading image");
            throw error; // Stop execution if image upload fails
          });
      }

      if (currentPostId) {
        const postRef = doc(db, "blogs", currentPostId);
        await updateDoc(postRef, {
          title,
          content,
          imageUrl: imageUrl || imagePreview ? imageUrl : undefined,
        });
        toast.success("Blog post updated successfully!");
        setCurrentPostId(null);
        setShowModal(false);
      } else {
        await addDoc(collection(db, "blogs"), {
          title,
          content,
          imageUrl,
          createdAt: serverTimestamp(),
        });
        toast.success("Blog post added successfully!");
      }

      setTitle("");
      setContent("");
      setImage(null);
      setImagePreview(null);
      fetchPosts();
    } catch (error) {
      console.error("Error adding/updating blog post: ", error);
      toast.error("Error adding/updating blog post: Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substr(0, maxLength) + "...";
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <>
      <ToastContainer />
      <AdminHeader />
      <div className="container-fluid p-0">
        <div className="row">
          <Sidebar />
          <div className="col-md-9">
            <div className="container mt-5 ">
              <h2 className="mb-4 text-center">Add Blog Post</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="content" className="form-label">
                    Content
                  </label>
                  <ReactQuill
                    value={content}
                    onChange={setContent}
                    modules={AddPost.modules}
                    formats={AddPost.formats}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="image" className="form-label">
                    Image
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="image"
                    onChange={handleImageChange}
                  />
                  {imagePreview && (
                    <div
                      className="mt-3 position-relative"
                      style={{ display: "inline-block" }}
                    >
                      <img
                        src={imagePreview}
                        alt="Selected"
                        className="rounded-5"
                        style={{ maxWidth: "100%", width: "200px", height: "auto" }}
                      />
                      <button
                        type="button"
                        className="btn position-absolute d-flex align-items-center justify-content-center"
                        style={{
                          top: "5px",
                          right: "5px",
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          border: "none",
                          borderRadius: "50%",
                          width: "24px",
                          height: "24px",
                          color: "white",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={handleImageDelete}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading
                    ? "Processing..."
                    : currentPostId
                    ? "Update Post"
                    : "Add Post"}
                </button>
              </form>
            </div>
            <div className="container p-3">
              <h2 className="mb-4 text-center">Manage Blogs Posts Here</h2>
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-striped table-responsive">
                    <thead>
                      <tr className="text-center">
                        <th>Title</th>
                        <th>Description</th>
                        <th>Image</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr className="text-center">
                          <td colSpan={4}>Loading...</td>
                        </tr>
                      ) : (
                        posts.map((post) => (
                          <tr key={post.id}>
                            <td>{post.title}</td>
                            <td>{truncateText(stripHtmlTags(post.content), 50)}</td>
                            <td>
                              {post.imageUrl && (
                                <img
                                  src={post.imageUrl}
                                  alt={post.title}
                                  style={{ width: "50px", height: "auto" }}
                                />
                              )}
                            </td>
                            <td className="d-flex gap-1">
                              <button
                                className="btn btn-sm btn-primary me-2"
                                onClick={() => handleEdit(post.id)}
                              >
                                <i className="fa fa-edit"></i>
                              </button>
                              <button
                                className="btn btn-sm btn-danger"
                                onClick={() => handleDelete(post.id, post.imageUrl)}
                                disabled={loading}
                              >
                                {delLoading ? "Deleting..." : <i className="fa fa-trash"></i>}
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for editing */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Blog Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="modalTitle" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="modalTitle"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="modalContent" className="form-label">
                Content
              </label>
              <ReactQuill
                value={content}
                onChange={setContent}
                modules={AddPost.modules}
                formats={AddPost.formats}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="modalImage" className="form-label">
                Image
              </label>
              <input
                type="file"
                className="form-control"
                id="modalImage"
                onChange={handleImageChange}
              />
              {imagePreview && (
                <div
                  className="mt-3 position-relative"
                  style={{ display: "inline-block" }}
                >
                  <img
                    src={imagePreview}
                    alt="Selected"
                    className="rounded-5"
                    style={{ maxWidth: "100%", width: "200px", height: "auto" }}
                  />
                  <button
                    type="button"
                    className="btn position-absolute d-flex align-items-center justify-content-center"
                    style={{
                      top: "5px",
                      right: "5px",
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      border: "none",
                      borderRadius: "50%",
                      width: "24px",
                      height: "24px",
                      color: "white",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={handleImageDelete}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              )}
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Processing..." : "Update Post"}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

AddPost.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["clean"],
  ],
};

AddPost.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
];

export default AddPost;
