import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Form } from "react-bootstrap";
import Footer from "../../../Components/Footer";
import AdminHeader from "../../../Components/AdminHeader";
import Sidebar from "../../../Components/Sidebar";

const UsersData = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [vehicleUse, setVehicleUse] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const usersCollection = collection(db, "Users");
      const snapshot = await getDocs(usersCollection);
      const usersData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users: ", error);
      toast.error("Failed to fetch users. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (userId) => {
    const userToEdit = users.find((user) => user.id === userId);
    if (userToEdit) {
      setCurrentUserId(userId);
      setName(userToEdit.name);
      setEmail(userToEdit.email);
      setAddress(userToEdit.address);
      setPhoneNumber(userToEdit.phoneNumber);
      setVehicleUse(userToEdit.vehicleUse);
      setShowModal(true);
    }
  };

  const handleDelete = async (userId) => {
    setLoading(true);
    try {
      const userRef = doc(db, "Users", userId);
      await deleteDoc(userRef);
      toast.success("User deleted successfully!");
      fetchUsers(); // Refresh users after deletion
    } catch (error) {
      console.error("Error deleting user: ", error);
      toast.error("Failed to delete user. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (currentUserId) {
        const userRef = doc(db, "Users", currentUserId);
        await updateDoc(userRef, {
          name,
          email,
          address,
          phoneNumber,
          vehicleUse,
        });
        toast.success("User updated successfully!");
      } else {
        await addDoc(collection(db, "Users"), {
          name,
          email,
          address,
          phoneNumber,
          vehicleUse,
        });
        toast.success("User added successfully!");
      }
      // Clear form fields and close modal
      setCurrentUserId(null);
      setName("");
      setEmail("");
      setAddress("");
      setPhoneNumber("");
      setVehicleUse("");
      fetchUsers(); // Refresh users after update
      setShowModal(false);
    } catch (error) {
      console.error("Error updating user: ", error);
      toast.error("Failed to update user. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentUserId(null);
    setName("");
    setEmail("");
    setAddress("");
    setPhoneNumber("");
    setVehicleUse("");
  };

  return (
    <>
      <ToastContainer />
      <AdminHeader />
      <div className="container-fluid p-0">
        <div className="row">
          <Sidebar />
          <div className="col-md-9">
            <div className="mt-5">
              <h2 className="mb-4 text-center">Users Informations</h2>
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-striped">
                    <thead>
                      <tr className="text-center">
                        <th>Name</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Phone Number</th>
                        <th>Vehicle Use</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => (
                        <tr key={user.id} className="text-center">
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.address}</td>
                          <td>{user.phoneNumber}</td>
                          <td>{user.vehicleUse}</td>
                          <td className="d-flex gap-1">
                            <button
                              className="btn btn-sm btn-primary me-2"
                              onClick={() => handleEdit(user.id)}
                              disabled={loading}
                            >
                              <i className="fa fa-edit"></i>
                            </button>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => handleDelete(user.id)}
                              disabled={loading}
                            >
                              {loading ? "Deleting..." : <i className="fa fa-trash"></i>}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit User Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{currentUserId ? "Edit User" : "Add User"}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleUpdate}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="editName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editVehicleUse">
              <Form.Label>Vehicle Use</Form.Label>
              <Form.Control
                type="text"
                value={vehicleUse}
                onChange={(e) => setVehicleUse(e.target.value)}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button type="submit" variant="primary" disabled={loading}>
              {loading ? "Updating..." : currentUserId ? "Update" : "Add User"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default UsersData;
