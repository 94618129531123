import React, { useEffect, useState, useMemo } from "react";
import AdminHeader from "../../../Components/AdminHeader";
import Sidebar from "../../../Components/Sidebar";
import {
  Pagination,
  Form,
  Table,
  Container,
  Row,
  Col,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import DeleteImageButton from "../../../Components/DeleteImageButton";

const AllProducts = () => {
  const [cars, setCars] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [itemsPerPage] = useState(10);

  // State for form data
  const [formData, setFormData] = useState({
    car_name: "",
    maker_name: "",
    model_name: "",
    car_type: "",
    engine_capacity: "",
    service_type: "",
    product_code: "",
    head_title: "",
    product_desc: "",
    prices: [{ plan_name: "", price: "", plan_duration: "" }],
  });

  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [state, setState] = useState({ toggle: false });
  const setToggle = () => {
    if (state) { // Check if state is defined
      setState(prevState => ({
        ...prevState,
        toggle: !prevState.toggle
      }));
    } else {
      console.error('State is not defined');
    }
  };

  const handleCloseModal = () => {
    setShowUpdateModal(false);
    setShowAddModal(false);
    setPreviewImages([]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars"
      );
      const data = await response.json();
      if (data.status === 200) {
        // Assuming the cars data has a field 'updated_at' or 'created_at'
        const sortedCars = data.cars.sort((a, b) =>
          new Date(b.updated_at) - new Date(a.updated_at)
        );
        setCars(sortedCars);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const filteredCars = useMemo(() => {
    if (!searchTerm) return cars;
    return cars.filter(
      (car) =>
        car.car_name.toLowerCase().includes(searchTerm) ||
        car.maker.some(
          (maker) =>
            maker.maker_name.toLowerCase().includes(searchTerm) ||
            maker.models.some(
              (model) =>
                model.model_name.toLowerCase().includes(searchTerm) ||
                model.car_type.toLowerCase().includes(searchTerm) ||
                model.engine_capacity
                  .toString()
                  .toLowerCase()
                  .includes(searchTerm) ||
                model.service_type.toLowerCase().includes(searchTerm) ||
                model.prices.some(
                  (price) =>
                    price.plan_name.toLowerCase().includes(searchTerm) ||
                    price.price.toString().toLowerCase().includes(searchTerm)
                )
            )
        )
    );
  }, [cars, searchTerm]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = async (carId) => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://phplaravel-1324103-4877027.cloudwaysapps.com/api/delete-cars-model/${carId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        toast.success("Data Deleted Successfully");
        setCars(cars.filter((car) => car.id !== carId));
        fetchData();
      } else {
        toast.error("Failed to delete data");
      }
    } catch (error) {
      toast.error("Failed to delete data");
      console.error("Error deleting data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (car, maker, model) => {
    console.log("Selected Car:", car);
    console.log("Selected Maker:", maker);
    console.log("Selected Model:", model);

    if (!maker || !model) {
      console.error("Maker or model data is missing.");
      return;
    }

    setFormData({
      car_name: car.car_name || "",
      maker_name: maker.maker_name || "",
      model_name: model.model_name || "",
      car_type: model.car_type || "",
      engine_capacity: model.engine_capacity || "",
      service_type: model.service_type || "",
      product_code: model.product_code || "",
      head_title: model.head_title || "",
      product_desc: model.product_desc || "",
      prices: model.prices || [{ plan_name: "", price: "", plan_duration: "" }],
    });

    const existingImages = model.images || [];
    const existingImageUrls = existingImages.map(
      (image) => `https://phplaravel-1324103-4877027.cloudwaysapps.com/uploads/images/${image.car_image}`
    );

    console.log("Existing Images:", existingImages);
    console.log("Preview Image URLs:", existingImageUrls);

    setImages(existingImages); // Set the images state with existing images
    setPreviewImages(existingImageUrls); // Set preview images for the existing images

    setSelectedCar(car); // Ensure selectedCar is set before opening the modal
    setShowUpdateModal(true);
  };


  const handleFormSubmit = async (url, method) => {
    const formDataToSubmit = new FormData();

    // Append basic form data
    formDataToSubmit.append("car_name", formData.car_name.trim());
    formDataToSubmit.append("maker_name", formData.maker_name.trim());
    formDataToSubmit.append("model_name", formData.model_name.trim());
    formDataToSubmit.append("car_type", formData.car_type.trim());
    formDataToSubmit.append("engine_capacity", formData.engine_capacity.trim());
    formDataToSubmit.append("service_type", formData.service_type.trim());
    formDataToSubmit.append("product_code", formData.product_code.trim());
    formDataToSubmit.append("product_desc", formData.product_desc.trim());
    formDataToSubmit.append("head_title", formData.head_title.trim());

    // Validate and append prices
    let allPricesValid = true;
    formData.prices.forEach((price, index) => {
      if (!price.plan_name || !price.price || !price.plan_duration) {
        toast.error(`Please fill out all fields for price plan ${index + 1}`);
        allPricesValid = false;
        return;
      }
      const cleanedPrice = parseFloat(price.price.replace(/[^0-9.]/g, ""));
      if (isNaN(cleanedPrice)) {
        toast.error(`Invalid price format for price plan ${index + 1}`);
        allPricesValid = false;
        return;
      }
      formDataToSubmit.append(
        `prices[${index}][plan_name]`,
        price.plan_name.trim()
      );
      formDataToSubmit.append(`prices[${index}][price]`, cleanedPrice);
      formDataToSubmit.append(
        `prices[${index}][plan_duration]`,
        price.plan_duration.trim()
      );
    });

    if (!allPricesValid) {
      return;
    }

    // Handle images
    images.forEach((image) => {
      if (image instanceof File) {
        formDataToSubmit.append("images[]", image);
      } else {
        // Ensure that existing images are included
        formDataToSubmit.append("existing_images[]", image.car_image);
      }
    });

    // Handle videos
    if (videos.length > 0) {
      videos.forEach((video) => {
        formDataToSubmit.append("videos[]", video);
      });
    }

    try {
      const response = await fetch(url, {
        method,
        body: formDataToSubmit,
      });

      if (!response.ok) {
        let errorData;
        try {
          errorData = await response.json();
        } catch (err) {
          console.error("Failed to parse error response as JSON:", err);
        }

        if (response.status === 422 && errorData) {
          console.error("Validation error:", errorData);
          toast.error(`Validation error: ${JSON.stringify(errorData.errors)}`);
        } else {
          console.error("Server response was not OK", response);
          toast.error(`Failed to submit data: ${response.statusText}`);
        }
        return;
      }

      const successMessage =
        method === "POST"
          ? "Car added successfully!"
          : "Car updated successfully!";
      toast.success(successMessage);

      // Refetch the data or manually update the state
      fetchData();
      handleCloseModal(); // Close the modal
    } catch (error) {
      toast.error("Failed to submit data");
      console.error("Error submitting data:", error);
    }
  };


  // const handleFormSubmit = async (url, method) => {
  //   const formDataToSubmit = new FormData();

  //   // Append basic form data
  //   formDataToSubmit.append("car_name", formData.car_name.trim());
  //   formDataToSubmit.append("maker_name", formData.maker_name.trim());
  //   formDataToSubmit.append("model_name", formData.model_name.trim());
  //   formDataToSubmit.append("car_type", formData.car_type.trim());
  //   formDataToSubmit.append("engine_capacity", formData.engine_capacity.trim());
  //   formDataToSubmit.append("service_type", formData.service_type.trim());
  //   formDataToSubmit.append("product_code", formData.product_code.trim());
  //   formDataToSubmit.append("product_desc", formData.product_desc.trim());
  //   formDataToSubmit.append("head_title", formData.head_title.trim());

  //   // Validate and append prices
  //   let allPricesValid = true;
  //   formData.prices.forEach((price, index) => {
  //     if (!price.plan_name || !price.price || !price.plan_duration) {
  //       toast.error(`Please fill out all fields for price plan ${index + 1}`);
  //       allPricesValid = false;
  //       return;
  //     }
  //     const cleanedPrice = parseFloat(price.price.replace(/[^0-9.]/g, ""));
  //     if (isNaN(cleanedPrice)) {
  //       toast.error(`Invalid price format for price plan ${index + 1}`);
  //       allPricesValid = false;
  //       return;
  //     }
  //     formDataToSubmit.append(
  //       `prices[${index}][plan_name]`,
  //       price.plan_name.trim()
  //     );
  //     formDataToSubmit.append(`prices[${index}][price]`, cleanedPrice);
  //     formDataToSubmit.append(
  //       `prices[${index}][plan_duration]`,
  //       price.plan_duration.trim()
  //     );
  //   });

  //   if (!allPricesValid) {
  //     return;
  //   }

  //   // Handle images
  //   images.forEach((image) => {
  //     if (image instanceof File) {
  //       formDataToSubmit.append("images[]", image);
  //     } else {
  //       // Ensure that existing images are included
  //       formDataToSubmit.append("existing_images[]", image.car_image);
  //     }
  //   });

  //   // Handle videos
  //   if (videos.length > 0) {
  //     videos.forEach((video) => {
  //       formDataToSubmit.append("videos[]", video);
  //     });
  //   }

  //   try {
  //     const response = await fetch(url, {
  //       method,
  //       body: formDataToSubmit,
  //     });

  //     if (!response.ok) {
  //       let errorData;
  //       try {
  //         errorData = await response.json();
  //       } catch (err) {
  //         console.error("Failed to parse error response as JSON:", err);
  //       }

  //       if (response.status === 422 && errorData) {
  //         console.error("Validation error:", errorData);
  //         toast.error(`Validation error: ${JSON.stringify(errorData.errors)}`);
  //       } else {
  //         console.error("Server response was not OK", response);
  //         toast.error(`Failed to submit data: ${response.statusText}`);
  //       }
  //       return;
  //     }

  //     const successMessage =
  //       method === "POST"
  //         ? "Car added successfully!"
  //         : "Car updated successfully!";
  //     toast.success(successMessage);
  //     fetchData();
  //     setShowUpdateModal(false);
  //     setShowAddModal(false);
  //   } catch (error) {
  //     toast.error("Failed to submit data");
  //     console.error("Error submitting data:", error);
  //   }
  // };


  const handleFileChange = (e, setFiles) => {
    const files = Array.from(e.target.files);
    setFiles(files);

    if (e.target.name === "images") {
      const previews = files.map((file) => URL.createObjectURL(file));
      setPreviewImages(previews);
    }

    console.log("Selected files:", files); // Log the selected files for debugging
  };


  const handleArrayChange = (e, index, arrayName) => {
    const { name, value } = e.target;
    const updatedArray = [...formData[arrayName]];
    updatedArray[index] = {
      ...updatedArray[index],
      [name]: value.trim(),
    };
    setFormData((prevData) => ({
      ...prevData,
      [arrayName]: updatedArray,
    }));

    console.log("Updated Array Data:", updatedArray); // Log the updated array
  };

  const handleUpdateSubmit = () => {
    handleFormSubmit(
      `https://phplaravel-1324103-4877027.cloudwaysapps.com/api/update-all-car-data/${selectedCar.id}`,
      "POST"
    );
  };

  const handleAdd = () => {
    console.log("Adding a new car"); // Log action
    setFormData({
      car_name: "",
      maker_name: "",
      model_name: "",
      car_type: "",
      engine_capacity: "",
      service_type: "",
      product_code: "",
      head_title: "",
      product_desc: "",
      prices: [{ plan_name: "", price: "", plan_duration: "" }],
    });
    setImages([]);
    setVideos([]);
    setPreviewImages([]); // Clear preview images
    setShowAddModal(true);
  };

  const handleAddSubmit = () => {
    console.log("Submitting new car data"); // Log action
    handleFormSubmit("https://phplaravel-1324103-4877027.cloudwaysapps.com/api/car-all-data", "POST");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const addArrayField = (arrayName) => {
    setFormData((prevData) => ({
      ...prevData,
      [arrayName]: [
        ...prevData[arrayName],
        { plan_name: "", price: "", plan_duration: "" },
      ],
    }));
  };

  // const handleFileChange = (e, setFiles) => {
  //   const files = Array.from(e.target.files);
  //   setFiles(files);

  //   if (e.target.name === "images") {
  //     const previews = files.map((file) =>
  //       URL.createObjectURL(file)
  //     );
  //     setPreviewImages(previews);
  //   }
  // };

  const totalPages = Math.ceil(filteredCars.length / itemsPerPage);
  const displayedCars = filteredCars.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const handleRemoveImage = async (imageId, carId) => {
    try {
      if (!imageId || !carId) {
        console.error("imageId or carId is undefined or null:", imageId, carId);
        toast.error("Image ID or Car ID is not valid. Cannot remove image.");
        return;
      }

      console.log("Attempting to remove image with ID:", imageId, "for car ID:", carId);

      const response = await fetch(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/deletecarimg/${imageId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: imageId }) // Send the image ID in the body
      });

      if (response.ok) {
        toast.success("Image removed successfully.");

        // Update the images state to remove the deleted image by ID
        const updatedImages = images.filter((image) => image.id !== imageId);
        setImages(updatedImages);

        // Update the previewImages state to remove the corresponding URL
        const updatedPreviewImages = updatedImages.map(
          (image) => `https://phplaravel-1324103-4877027.cloudwaysapps.com/uploads/images/${image.car_image}`
        );
        setPreviewImages(updatedPreviewImages);

        console.log("Updated Images:", updatedImages);
        console.log("Updated Preview Images:", updatedPreviewImages);

        // Ensure there's no conditional mismatch
        if (updatedImages.length === 0) {
          console.log("No images left after deletion");
        }
      } else {
        const errorText = await response.text();
        console.error("Failed to remove image:", errorText);
        toast.error("Failed to remove image. Please try again.");
      }
    } catch (error) {
      console.error("Error removing image:", error);
      toast.error("Failed to remove image. Please try again.");
    }
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <AdminHeader />
      <Container fluid>
        <Row>
          <Sidebar />
          <Col md={9}>
            <Container className="mt-4">
              <h2>Cars Information</h2>

              <Button variant="success" className="mb-3" onClick={handleAdd}>
                Add New Car
              </Button>

              <Form className="mb-3">
                <Form.Group>
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Search by Car Name, Maker Name, Model Name, etc."
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </Form.Group>
              </Form>

              <Table striped bordered hover responsive>
                <thead>
                  <tr style={{ fontSize: "16px" }}>
                    <th>#ID</th>
                    <th>Car_Name</th>
                    <th>Maker_Name</th>
                    <th>Model_Name</th>
                    <th>Car_Type</th>
                    <th>Engine_Capacity</th>
                    <th>Service_Type</th>
                    <th>Car_Images</th>
                    <th>Added_Plans_Details</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedCars.length > 0 ? (
                    displayedCars.map((car, carIndex) =>
                      car.maker.map((maker, makerIndex) =>
                        maker.models.map((model, modelIndex) => (
                          <tr
                            key={`${car.id}-${maker.id}-${model.id}`}
                            style={{ fontSize: "13px" }}
                          >
                            <td>{carIndex + 1}</td>
                            <td>{car.car_name}</td>
                            <td>{maker.maker_name}</td>
                            <td>{model.model_name}</td>
                            <td>{model.car_type}</td>
                            <td>{model.engine_capacity}</td>
                            <td>{model.service_type}</td>
                            <td className="d-flex flex-wrap flex-row gap-1 align-items-center">
                              {model.images && model.images.length > 0 ? (
                                model.images.map((image, index) => (
                                  <div key={image.id} className="position-relative">
                                    <Image
                                      src={`https://phplaravel-1324103-4877027.cloudwaysapps.com/uploads/images/${image.car_image}`}
                                      alt="image"
                                      thumbnail
                                      style={{ maxWidth: "80px" }}
                                    />
                                    {/* <small className="d-block text-muted">ID: {image.id}</small> */}
                                    <DeleteImageButton
                                      onClick={() => {
                                        console.log("Deleting image with ID:", image.id); // Log the image ID
                                        handleRemoveImage(image.id, car.id);
                                      }}
                                    />
                                  </div>
                                ))
                              ) : (
                                <span>No images</span>
                              )}
                            </td>


                            <td>
                              {model.prices && model.prices.length > 0 ? (
                                model.prices.map((price, index) => (
                                  <div key={index}>
                                    Plans: {price.plan_name} - Price: {price.price}
                                  </div>
                                ))
                              ) : (
                                <span>No prices</span>
                              )}
                            </td>
                            <td className="d-flex gap-1">
                              <Button
                                variant="info"
                                onClick={() => handleUpdate(car, maker, model)}
                              >
                                <i className="fa-solid fa-edit"></i>
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => handleDelete(model.id)}
                                disabled={loading}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan="10">No records found</td>
                    </tr>
                  )}
                </tbody>
              </Table>


              <Pagination>
                {[...Array(totalPages).keys()].map((pageNumber) => (
                  <Pagination.Item
                    key={pageNumber + 1}
                    active={currentPage === pageNumber + 1}
                    onClick={() => handlePageChange(pageNumber + 1)}
                  >
                    {pageNumber + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </Container>
          </Col>
        </Row>
      </Container>

      <Modal show={showUpdateModal || showAddModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {showAddModal ? "Add New Car" : "Update Car"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCarName">
              <Form.Label>Car Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Car Name"
                name="car_name"
                value={formData.car_name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formMakerName">
              <Form.Label>Maker Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Maker Name"
                name="maker_name"
                value={formData.maker_name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formModelName">
              <Form.Label>Model Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Model Name"
                name="model_name"
                value={formData.model_name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formCarType">
              <Form.Label>Car Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Car Type"
                name="car_type"
                value={formData.car_type}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formEngineCapacity">
              <Form.Label>Engine Capacity</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Engine Capacity"
                name="engine_capacity"
                value={formData.engine_capacity}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formServiceType">
              <Form.Label>Service Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Service Type"
                name="service_type"
                value={formData.service_type}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formProductDesc">
              <Form.Label>Product Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Product Description"
                name="product_desc"
                value={formData.product_desc}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formProductCode">
              <Form.Label>Product Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product Code"
                name="product_code"
                value={formData.product_code}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formHeadTitle">
              <Form.Label>Head Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Head Title"
                name="head_title"
                value={formData.head_title}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Prices</Form.Label>
              {formData.prices.map((price, index) => (
                <div key={index} className="mb-3">
                  <Row>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="Plan Name"
                        name="plan_name"
                        value={price.plan_name}
                        onChange={(e) => handleArrayChange(e, index, "prices")}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="Plan Duration"
                        name="plan_duration"
                        value={price.plan_duration}
                        onChange={(e) => handleArrayChange(e, index, "prices")}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="Price"
                        name="price"
                        value={price.price}
                        onChange={(e) => handleArrayChange(e, index, "prices")}
                      />
                    </Col>
                  </Row>
                </div>
              ))}
              <Button
                variant="secondary"
                onClick={() => addArrayField("prices")}
                className="mb-3"
              >
                Add Price
              </Button>
            </Form.Group>

            {/* Display existing images */}
            <Form.Group>
              <Form.Label>Existing Images</Form.Label>
              <div className="d-flex flex-wrap gap-2">
                {previewImages.length > 0 ? (
                  previewImages.map((imageUrl, index) => {
                    const imageId = images.find(image => `https://phplaravel-1324103-4877027.cloudwaysapps.com/uploads/images/${image.car_image}` === imageUrl)?.id;
                    return (
                      <div key={imageId} className="position-relative">
                        <Image
                          src={imageUrl}
                          alt="existing image"
                          thumbnail
                          style={{ maxWidth: "80px", maxHeight: "80px" }}
                        />
                        <small className="d-block text-muted">Path: {imageUrl}</small>
                        {/* <DeleteImageButton
                          onClick={() => {
                            console.log("Deleting image with ID:", imageId);
                            handleRemoveImage(imageId, selectedCar.id);
                          }}
                        /> */}
                      </div>
                    );
                  })
                ) : (
                  <span>No images available</span>
                )}
              </div>
            </Form.Group>

            {/* Display preview images for new car */}
            {videos.length > 0 && !showAddModal && (
              <Form.Group>
                <Form.Label>Existing Videos</Form.Label>
                <div className="d-flex flex-wrap gap-2">
                  {videos.map((video, index) => (
                    <div key={index}>
                      <video
                        src={`https://serve.servebiznes.com/uploads/videos/${video.car_video}`}
                        controls
                        style={{ maxWidth: "150px", maxHeight: "100px" }}
                      />
                    </div>
                  ))}
                </div>
              </Form.Group>
            )}

            <Form.Group controlId="formImages">
              <Form.Label>Add/Replace Images</Form.Label>
              <Form.Control
                type="file"
                multiple
                name="images"
                onChange={(e) => handleFileChange(e, setImages)}
              />
            </Form.Group>

            <Form.Group controlId="formVideos">
              <Form.Label>Add/Replace Videos</Form.Label>
              <Form.Control
                type="file"
                multiple
                name="videos"
                onChange={(e) => handleFileChange(e, setVideos)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={showAddModal ? handleAddSubmit : handleUpdateSubmit}
          >
            {showAddModal ? "Add Car" : "Update Car"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AllProducts;
