import React, { useState, useContext, useEffect } from "react";
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import img from "../../../assets/images/side_image.png";
import img2 from "../../../assets/images/uk.png";
import { StepContext } from "../../../Context/StepContext";
import '../../Quotes/Quote.css';

const StepOne = ({ modelValue }) => {
  const { setActiveStep, updateFormData } = useContext(StepContext);
  const [loading, setLoading] = useState(false);
  const [makers, setMakers] = useState([]);
  const [selectedCar, setSelectedCar] = useState('');
  const [models, setModels] = useState([]);
  const [range, setRange] = useState("");
  const [isCarValid, setIsCarValid] = useState(false);
  const [cars, setCars] = useState([]);
  const [selectedMaker, setSelectedMaker] = useState('');
  const [selectedYear, setSelectedYear] = useState(''); // Add this state for year
  const [year, setYear] = useState('');
  const [yearError, setYearError] = useState('');

  const [formData, setFormData] = useState({
    carRegistration: "",
    make: "",
    model: modelValue || "",
    Year: "",
    range: "",
    motExpiryDate: "",
    firstRegistrationDate: "",
    estimatedMileage: "",
  });

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await axios.get('https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars');
        if (response.data && Array.isArray(response.data.cars)) {
          setCars(response.data.cars);
        } else {
          console.error('Unexpected response data format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching car data:', error);
      }
    };

    fetchCars();
  }, []);

  useEffect(() => {
    const fetchMakers = async () => {
      if (!selectedCar) return; // Avoid fetching if no car is selected
      try {
        const response = await axios.get(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-maker?car_id=${selectedCar}`);
        if (response.data && Array.isArray(response.data.maker)) {
          setMakers(response.data.maker);
        } else {
          console.error('Unexpected response data format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching makers:', error);
      }
    };

    fetchMakers();
  }, [selectedCar]);

  useEffect(() => {
    const fetchModels = async () => {
      if (!selectedCar || !selectedMaker) return; // Avoid fetching if car or maker is not selected
      try {
        const response = await axios.get('https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-models', {
          params: {
            car_id: selectedCar,
            maker_id: selectedMaker
          }
        });
        if (response.data && Array.isArray(response.data.models)) {
          console.log('Models data:', response.data.models); // Log the models data
          setModels(response.data.models);
        } else {
          console.error('Unexpected response data format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    };

    fetchModels();
  }, [selectedCar, selectedMaker]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.toUpperCase(),
    }));

    if (name === 'carRegistration') {
      validateCarRegistration(value.toUpperCase());
    }
  };

  const handleMakerChange = (e) => {
    const { value } = e.target;
    setSelectedMaker(value);
  };


  const handleYearChange = (e) => {
    const selectedYear = e.target.value;
    setSelectedYear(selectedYear);
    validateYear(selectedYear);
  };

  const validateYear = (year) => {
    if (year >= 2012 && year <= 2024) {
      setYearError('');
    } else {
      setYearError('Services not available for such old cars. Please select a car whose year is above 2012.');
    }
  };

  const validateCarRegistration = async (carNumber) => {
    if (carNumber.length >= 4) {
      try {
        const response = await fetch(`https://api.example.com/validate-car/${carNumber}`);
        const data = await response.json();
        setIsCarValid(data.isValid);
      } catch (error) {
        console.error("Error validating car registration:", error);
        setIsCarValid(false);
      }
    } else {
      setIsCarValid(false);
    }
  };

  const handleSubmit = async (e) => {
    console.log("selected car name is ", selectedCar.car_name);
    e.preventDefault();
    if (formData.carRegistration.length < 4) {
      toast.error("Car registration number must be at least 4 characters");
      return;
    }

    if (parseInt(selectedYear, 10) < 2012) {
      toast.error("Services not available for cars older than 2012. Please select a valid year.");
      return;
    }
    
    try {
      setLoading(true);
      updateFormData('stepOneData', { ...formData, selectedCar, selectedMaker, selectedYear });
      console.log("Form submitted successfully. Moving to Step 2.", formData, selectedCar, selectedMaker, selectedYear);
      setActiveStep(2);
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit the form.");
    } finally {
      setLoading(false);
    }
  };

  const handleFindCar = () => {
    if (formData.carRegistration.length < 4) {
      toast.error("Please enter at least 4 characters for car registration number");
    } else {
      const form = document.getElementById('show_form');
      if (form) {
        form.style.display = 'block';
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Container>
        <Row className="mb-4">
          <Col lg={6} md={6} sm={12} className="d-flex flex-wrap">
            <Image src={img} fluid />
          </Col>
          <Col lg={6} md={6} sm={12} className="d-flex flex-wrap">
            <div className="offer">TV OFFER</div>
            <div>
              SAVE <span className="discount">10%</span>{" "}
              <span className="new-discount">11%</span>
            </div>
            <div className="important">
              IMPORTANT: This offer cannot be used in conjunction with any other
              offer.
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={7} className="p-4" style={{ backgroundColor: "#f1f1f1" }}>
            <div className="motor_info d-flex justify-content-between align-items-center mb-4">
              <h4>My Motor Info</h4>
              <h5>
                <span style={{ color: "#f00" }}>*</span>Mandatory field
              </h5>
            </div>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column lg={4} className="font_style_car_registration">
                Car Registration
              </Form.Label>
              <Col lg={6} className="d-flex align-items-center">
                <Image src={img2} alt="" />
                <Form.Control
                  type="text"
                  id="carRegistration"
                  name="carRegistration"
                  value={formData.carRegistration}
                  onChange={handleChange}
                  required
                />
                {isCarValid && (
                  <span style={{ color: "green", marginLeft: "10px" }}>✓</span>
                )}
              </Col>
              <Col lg={2} className="d-flex justify-content-end">
                <Button variant="primary" onClick={handleFindCar}>Find Car &gt;</Button>
              </Col>
            </Form.Group>
            <div className="have_an_account mb-4">
              <h6>Already Have an Account?</h6>
              <a href="javascript:void(0)">
                <h5 style={{ color: "red" }}>Sign in Here</h5>
              </a>
            </div>
            <div className="have_an_account">
              <a href="javascript:void(0)">
                <h5 className="text-dark">
                  Don't Know Your Registration? Click here
                </h5>
              </a>
            </div>
            <div id="show_form" style={{ display: "none" }}>
              <Form.Group className="mb-3">
                <Form.Label>Car:</Form.Label>
                <Form.Control
                  as="select"
                  id="make"
                  name="make"
                  value={selectedCar}
                  onChange={(e) => {
                    setSelectedCar(e.target.value);
                    handleChange(e);
                  }}
                  required
                >
                  <option value="">Select Car</option>
                  {cars.map((car) => (
                    <option key={car.id} value={car.id}>
                      {car.car_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Model:</Form.Label>
                <Form.Control
                  as="select"
                  id="model"
                  name="model"
                  value={selectedMaker}
                  onChange={handleMakerChange}
                  required
                >
                  <option value="">Select Model</option>
                  {makers.map((maker) => (
                    <option key={maker.id} value={maker.id}>
                      {maker.maker_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
  <Form.Label>Year:</Form.Label>
  <Form.Control
    as="select"
    id="year"
    name="year"
    value={selectedYear}
    onChange={handleYearChange}
    required
  >
    <option value="">Select Year</option>
    {models.map((model, index) => (
      <option key={index} value={model.model_name}>
        {model.model_name}
      </option>
    ))}
  </Form.Control>
  {selectedYear && (parseInt(selectedYear, 10) < 2012) && (
    <div style={{ color: "red", marginTop: "5px" }}>
      Services not available for such old cars. Please select a car whose year is above 2012.
    </div>
  )}
</Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Range:</Form.Label>
                <Form.Control
                  type="text"
                  id="range"
                  name="range"
                  value={range}
                  onChange={(e) => {
                    setRange(e.target.value);
                    handleChange(e);
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>MOT Expiry Date:</Form.Label>
                <Form.Control
                  type="date"
                  id="motExpiryDate"
                  name="motExpiryDate"
                  value={formData.motExpiryDate}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>First Registration Date:</Form.Label>
                <Form.Control
                  type="date"
                  id="firstRegistrationDate"
                  name="firstRegistrationDate"
                  value={formData.firstRegistrationDate}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Estimated Mileage:</Form.Label>
                <Form.Control
                  type="number"
                  id="estimatedMileage"
                  name="estimatedMileage"
                  value={formData.estimatedMileage}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <div className="d-flex justify-content-between align-items-center">
                <Button variant="secondary" onClick={() => setActiveStep(0)}>Back</Button>
                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? 'Submitting...' : 'Submit'}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Form>
  );
};

export default StepOne;
