import React, { useState } from "react";
import AdminHeader from "../../../Components/AdminHeader";
import Sidebar from "../../../Components/Sidebar";
import {
  Form,
  Container,
  Row,
  Col,
  Button,
  Image,
  Modal,
} from "react-bootstrap";

const UploadAllProducts = () => {
  const [carData, setCarData] = useState({
    car_name: "",
    maker: [
      {
        maker_name: "",
        models: [
          {
            model_name: "",
            car_type: "",
            engine_capacity: "",
            service_type: "",
            images: [],
            prices: [{ plan_name: "", price: "" }],
          },
        ],
      },
    ],
  });
  const [imagePreview, setImagePreview] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleInputChange = (e, makerIndex, modelIndex, priceIndex) => {
    const { name, value } = e.target;
    const updatedCarData = { ...carData };

    if (name.includes("car_name")) {
      updatedCarData.car_name = value;
    } else if (name.includes("maker_name")) {
      updatedCarData.maker[makerIndex].maker_name = value;
    } else if (name.includes("model_name")) {
      updatedCarData.maker[makerIndex].models[modelIndex].model_name = value;
    } else if (name.includes("car_type")) {
      updatedCarData.maker[makerIndex].models[modelIndex].car_type = value;
    } else if (name.includes("engine_capacity")) {
      updatedCarData.maker[makerIndex].models[modelIndex].engine_capacity =
        value;
    } else if (name.includes("service_type")) {
      updatedCarData.maker[makerIndex].models[modelIndex].service_type = value;
    } else if (name.includes("plan_name")) {
      updatedCarData.maker[makerIndex].models[modelIndex].prices[
        priceIndex
      ].plan_name = value;
    } else if (name.includes("price")) {
      updatedCarData.maker[makerIndex].models[modelIndex].prices[
        priceIndex
      ].price = value;
    }

    setCarData(updatedCarData);
  };

  const handleImageChange = (e, makerIndex, modelIndex) => {
    const files = Array.from(e.target.files);
    const updatedCarData = { ...carData };
    updatedCarData.maker[makerIndex].models[modelIndex].images = files.map(
      (file) => ({
        car_image: file.name,
      })
    );

    setImagePreview(files.map((file) => URL.createObjectURL(file)));
    setCarData(updatedCarData);
  };

  const handleAddModel = (makerIndex) => {
    const updatedCarData = { ...carData };
    updatedCarData.maker[makerIndex].models.push({
      model_name: "",
      car_type: "",
      engine_capacity: "",
      service_type: "",
      images: [],
      prices: [{ plan_name: "", price: "" }],
    });
    setCarData(updatedCarData);
  };

  const handleAddMaker = () => {
    const updatedCarData = { ...carData };
    updatedCarData.maker.push({
      maker_name: "",
      models: [
        {
          model_name: "",
          car_type: "",
          engine_capacity: "",
          service_type: "",
          images: [],
          prices: [{ plan_name: "", price: "" }],
        },
      ],
    });
    setCarData(updatedCarData);
  };

  const handleAddPrice = (makerIndex, modelIndex) => {
    const updatedCarData = { ...carData };
    updatedCarData.maker[makerIndex].models[modelIndex].prices.push({
      plan_name: "",
      price: "",
    });
    setCarData(updatedCarData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("car_name", carData.car_name);
      carData.maker.forEach((maker, makerIndex) => {
        formData.append(`maker[${makerIndex}][maker_name]`, maker.maker_name);
        maker.models.forEach((model, modelIndex) => {
          formData.append(
            `maker[${makerIndex}][models][${modelIndex}][model_name]`,
            model.model_name
          );
          formData.append(
            `maker[${makerIndex}][models][${modelIndex}][car_type]`,
            model.car_type
          );
          formData.append(
            `maker[${makerIndex}][models][${modelIndex}][engine_capacity]`,
            model.engine_capacity
          );
          formData.append(
            `maker[${makerIndex}][models][${modelIndex}][service_type]`,
            model.service_type
          );
          model.images.forEach((image, imageIndex) => {
            formData.append(
              `maker[${makerIndex}][models][${modelIndex}][images][${imageIndex}]`,
              image.car_image
            );
          });
          model.prices.forEach((price, priceIndex) => {
            formData.append(
              `maker[${makerIndex}][models][${modelIndex}][prices][${priceIndex}][plan_name]`,
              price.plan_name
            );
            formData.append(
              `maker[${makerIndex}][models][${modelIndex}][prices][${priceIndex}][price]`,
              price.price
            );
          });
        });
      });

      const response = await fetch(
        "https://serve.servebiznes.com/car-all-data",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        setShowConfirmation(true);
      } else {
        console.error("Error uploading data:", await response.text());
      }
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };

  return (
    <>
      <AdminHeader />
      <Container fluid>
        <Row>
          <Sidebar />
          <Col md={9}>
            <Container className="mt-4">
              <h2>Upload Car Data</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formCarName">
                  <Form.Label>Car Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="car_name"
                    value={carData.car_name}
                    onChange={handleInputChange}
                    placeholder="Enter car name"
                  />
                </Form.Group>

                {carData.maker.map((maker, makerIndex) => (
                  <div key={makerIndex}>
                    <Form.Group controlId={`formMakerName-${makerIndex}`}>
                      <Form.Label>Maker Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="maker_name"
                        value={maker.maker_name}
                        onChange={(e) => handleInputChange(e, makerIndex)}
                        placeholder="Enter maker name"
                      />
                    </Form.Group>

                    {maker.models.map((model, modelIndex) => (
                      <div key={modelIndex} style={{ marginLeft: "20px" }}>
                        <Form.Group controlId={`formModelName-${modelIndex}`}>
                          <Form.Label>Model Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="model_name"
                            value={model.model_name}
                            onChange={(e) =>
                              handleInputChange(e, makerIndex, modelIndex)
                            }
                            placeholder="Enter model name"
                          />
                        </Form.Group>

                        <Form.Group controlId={`formCarType-${modelIndex}`}>
                          <Form.Label>Car Type</Form.Label>
                          <Form.Control
                            type="text"
                            name="car_type"
                            value={model.car_type}
                            onChange={(e) =>
                              handleInputChange(e, makerIndex, modelIndex)
                            }
                            placeholder="Enter car type"
                          />
                        </Form.Group>

                        <Form.Group
                          controlId={`formEngineCapacity-${modelIndex}`}
                        >
                          <Form.Label>Engine Capacity</Form.Label>
                          <Form.Control
                            type="text"
                            name="engine_capacity"
                            value={model.engine_capacity}
                            onChange={(e) =>
                              handleInputChange(e, makerIndex, modelIndex)
                            }
                            placeholder="Enter engine capacity"
                          />
                        </Form.Group>

                        <Form.Group controlId={`formServiceType-${modelIndex}`}>
                          <Form.Label>Service Type</Form.Label>
                          <Form.Control
                            type="text"
                            name="service_type"
                            value={model.service_type}
                            onChange={(e) =>
                              handleInputChange(e, makerIndex, modelIndex)
                            }
                            placeholder="Enter service type"
                          />
                        </Form.Group>

                        <Form.Group controlId={`formImages-${modelIndex}`}>
                          <Form.Label>Images</Form.Label>
                          <Form.Control
                            type="file"
                            multiple
                            onChange={(e) =>
                              handleImageChange(e, makerIndex, modelIndex)
                            }
                          />
                          <div>
                            {imagePreview.map((imageSrc, index) => (
                              <Image
                                key={index}
                                src={imageSrc}
                                alt="Preview"
                                thumbnail
                                style={{ width: "50px", marginRight: "5px" }}
                              />
                            ))}
                          </div>
                        </Form.Group>

                        {model.prices.map((price, priceIndex) => (
                          <div key={priceIndex} style={{ marginLeft: "20px" }}>
                            <Form.Group
                              controlId={`formPlanName-${priceIndex}`}
                            >
                              <Form.Label>Plan Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="plan_name"
                                value={price.plan_name}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    makerIndex,
                                    modelIndex,
                                    priceIndex
                                  )
                                }
                                placeholder="Enter plan name"
                              />
                            </Form.Group>

                            <Form.Group controlId={`formPrice-${priceIndex}`}>
                              <Form.Label>Price</Form.Label>
                              <Form.Control
                                type="text"
                                name="price"
                                value={price.price}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    makerIndex,
                                    modelIndex,
                                    priceIndex
                                  )
                                }
                                placeholder="Enter price"
                              />
                            </Form.Group>
                          </div>
                        ))}

                        <Button
                          variant="primary"
                          onClick={() => handleAddPrice(makerIndex, modelIndex)}
                          style={{ marginTop: "10px" }}
                        >
                          Add Price Plan
                        </Button>
                      </div>
                    ))}

                    <Button
                      variant="primary"
                      onClick={() => handleAddModel(makerIndex)}
                      style={{ marginTop: "10px" }}
                    >
                      Add Model
                    </Button>
                  </div>
                ))}

                <Button
                  variant="primary"
                  onClick={handleAddMaker}
                  style={{ marginTop: "20px" }}
                >
                  Add Maker
                </Button>

                <Button
                  variant="success"
                  type="submit"
                  style={{ marginTop: "20px", marginLeft: "10px" }}
                >
                  Submit
                </Button>
              </Form>
            </Container>
          </Col>
        </Row>
      </Container>

      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Data uploaded successfully!</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmation(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadAllProducts;
