import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button, Container, Row, Col, Table, Modal } from "react-bootstrap";
import AdminHeader from "../../../Components/AdminHeader";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Sidebar from "../../../Components/Sidebar";
import CarModelManager from "../../../Components/CarModelManager";

const AddImages = () => {
  const services = [
    "Used Car Warranty",
    "Vehicle Inspection",
    "Vehicle Service",
    "Road Side",
    "Battery Replacement",
    "Windscreen Repair",
    "Home",
  ];

  const [selectedService, setSelectedService] = useState(services[0]);
  const [cars, setCars] = useState([]);
  const [makers, setMakers] = useState([]);
  const [selectedCar, setSelectedCar] = useState("");
  const [selectedMaker, setSelectedMaker] = useState("");
  const [models, setModels] = useState([]);
  const [selectedCarModel, setSelectedCarModel] = useState("");
  const [planName, setPlanName] = useState("");
  const [planDuration, setPlanDuration] = useState("");
  const [price, setPrice] = useState("");
  const [pricing, setPricing] = useState([]);
  const [editingPrice, setEditingPrice] = useState(null);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [files, setFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);

  // State for modal
  const [showModal, setShowModal] = useState(false);
  const [showModelManagerModal, setShowModelManagerModal] = useState(false); // State for CarModelManager modal
  const [modalData, setModalData] = useState({ planName: "", planDuration: "", price: "" });

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const carsData = await axios.get("https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars");
        setCars(carsData.data.cars);
      } catch (error) {
        console.error("Failed to fetch cars", error.message);
      }
    };

    fetchCars();
  }, []);

  useEffect(() => {
    const fetchMakers = async () => {
      if (!selectedCar) return;
      try {
        const response = await axios.get(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-maker?car_id=${selectedCar}`);
        if (response.data && Array.isArray(response.data.maker)) {
          setMakers(response.data.maker);
        } else {
          console.error("Unexpected response data format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching makers:", error);
      }
    };

    fetchMakers();
  }, [selectedCar]);

  useEffect(() => {
    const fetchModels = async () => {
      if (!selectedCar || !selectedMaker) return;
      try {
        const response = await axios.get("https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-models", {
          params: {
            car_id: selectedCar,
            maker_id: selectedMaker,
          },
        });
        setModels(response.data.models);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, [selectedCar, selectedMaker]);

  useEffect(() => {
    if (selectedCar && selectedMaker && selectedCarModel) {
      fetchImagesAndVideos(selectedCar, selectedMaker, selectedCarModel);
      fetchPricing(selectedCarModel);
    }
  }, [selectedCar, selectedMaker, selectedCarModel]);

  const fetchImagesAndVideos = async (carId, makerId, modelId) => {
    try {
      const response = await axios.post("https://phplaravel-1324103-4877027.cloudwaysapps.com/api/get-car-img", {
        car_id: carId,
        maker_id: makerId,
        model_id: modelId,
      });

      if (response.data.status === 200) {
        const imageList = response.data.all_images.map((img) => ({
          ...img,
          url: `${response.data.imagepath}${img.car_image}`,
        }));
        setImages(imageList);

        const videoList = response.data.all_videos.map((video) => ({
          ...video,
          url: `${response.data.videopath}${video.car_video}`,
        }));
        setVideos(videoList);
      } else {
        throw new Error(response.data.message || "Failed to fetch images and videos.");
      }
    } catch (error) {
      console.error("Error fetching images and videos:", error);
      toast.error("Failed to fetch images and videos.");
    }
  };
  console.log("fetched images are", images);
  const fetchPricing = async (modelId) => {
    try {
      const response = await axios.get(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/get-car-model-price/${modelId}`);
      if (response.data.status === 200) {
        setPricing(response.data.model_prices);
      } else {
        throw new Error("Failed to fetch pricing.");
      }
    } catch (error) {
      console.error("Error fetching pricing:", error);
      toast.error("Failed to fetch pricing.");
    }
  };

  const handleServiceChange = (e) => {
    setSelectedService(e.target.value);
  };

  const handleFileChange = (e) => {
    const filesArray = Array.from(e.target.files);
    setFiles(filesArray);

    const urls = filesArray.map((file) => URL.createObjectURL(file));
    setPreviewUrls(urls);
  };

  const handleVideoChange = (e) => {
    const videoArray = Array.from(e.target.files);
    const validVideos = videoArray.filter((video) => {
      const fileType = video.type;
      return fileType === "video/mp4" || fileType === "video/avi" || fileType === "video/mkv";
    });
  
    if (validVideos.length !== videoArray.length) {
      toast.error("Only mp4, avi, or mkv videos are allowed.");
    }
    setVideoFiles(validVideos);
  };
  

  const handleAddPricing = () => {
    if (!planName || !planDuration || !price) {
      toast.error("Please fill in all pricing fields.");
      return;
    }

    setPricing((prevPricing) => [
      ...prevPricing,
      { plan_name: planName, plan_duration: planDuration, price, id: Date.now() },
    ]);

    setPlanName("");
    setPlanDuration("");
    setPrice("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCar || !selectedMaker || !selectedCarModel || (!files.length && !videoFiles.length)) {
      toast.error("Please fill in all required fields.");
      return;
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("car_id", selectedCar);
      formData.append("maker_id", selectedMaker);
      formData.append("model_id", selectedCarModel);

      files.forEach((file) => {
        formData.append("car_images[]", file);
      });

      videoFiles.forEach((file) => {
        formData.append("car_videos[]", file);
      });

      const imageResponse = await axios.post("https://phplaravel-1324103-4877027.cloudwaysapps.com/api/store-car-img", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (imageResponse.data.status === 200) {
        toast.success("Images and videos uploaded successfully!");

        const pricingData = pricing.map((priceItem) => ({
          plan_name: priceItem.plan_name,
          plan_duration: priceItem.plan_duration,
          price: priceItem.price,
        }));

        const pricingResponse = await axios.post(
          "https://phplaravel-1324103-4877027.cloudwaysapps.com/api/store-car-model-price",
          {
            model_id: selectedCarModel,
            plan_name: pricingData.map((item) => item.plan_name),
            plan_duration: pricingData.map((item) => item.plan_duration),
            price: pricingData.map((item) => item.price),
          }
        );

        if (pricingResponse.data.status === 200) {
          toast.success("Pricing uploaded successfully!");

          // Reset all fields
          setSelectedCar("");
          setSelectedMaker("");
          setSelectedCarModel("");
          setPlanName("");
          setPlanDuration("");
          setPrice("");
          setFiles([]);
          setVideoFiles([]);
          setPreviewUrls([]);
          setPricing([]);

          fetchImagesAndVideos(selectedCar, selectedMaker, selectedCarModel);
          fetchPricing(selectedCarModel);
        } else {
          throw new Error("Failed to store car pricing.");
        }
      } else {
        throw new Error("Failed to store car images and videos.");
      }
    } catch (error) {
      console.error("Error uploading images, videos, and pricing:", error);
      toast.error("Failed to upload images, videos, and pricing.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditPricing = (price) => {
    setModalData({
      planName: price.plan_name,
      planDuration: price.plan_duration,
      price: price.price,
    });
    setEditingPrice(price);
    setShowModal(true);
  };

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleModalSubmit = async () => {
    try {
      const updatedPricing = {
        plan_name: modalData.planName,
        plan_duration: modalData.planDuration,
        price: modalData.price,
      };

      await axios.put(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/update-car-model-price/${editingPrice.id}`, updatedPricing);

      toast.success("Pricing updated successfully!");
      fetchPricing(selectedCarModel);
      setShowModal(false);
    } catch (error) {
      console.error("Error updating pricing:", error);
      toast.error("Failed to update pricing.");
    }
  };

  const handleDeletePricing = async (id) => {
    try {
      await axios.delete(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/deletecarmodelprice/${id}`);
      toast.success("Pricing deleted successfully!");
      fetchPricing(selectedCarModel);
    } catch (error) {
      console.error("Error deleting pricing:", error);
      toast.error("Failed to delete pricing.");
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const displayPricing = pricing.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const handleModelNotAvailable = () => {
    setShowModelManagerModal(true);
  };

  const handleDeleteImage = async (imageId) => {
    try {
      const response = await axios.delete(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/deletecarimg/${selectedCarModel}`)
        
      if (response.data.status === 200) {
        toast.success("Image deleted successfully!");
        setImages((prevImages) => prevImages.filter((image) => image.id !== imageId));
      } else {
        throw new Error("Failed to delete image.");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
      toast.error("Failed to delete image.");
    }
  };


  const handleCarModelChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCarModel(selectedValue);

    if (selectedValue === "other") {
      // Assuming "other" is the value for unavailable model
      handleModelNotAvailable();
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="col-md-9">
            <Container>
              <h1>Add Images, Videos, and Pricing</h1>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="serviceType">
                      <Form.Label>Service Type</Form.Label>
                      <Form.Select value={selectedService} onChange={handleServiceChange}>
                        {services.map((service, index) => (
                          <option key={index} value={service}>
                            {service}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="car">
                      <Form.Label>Select Car</Form.Label>
                      <Form.Select value={selectedCar} onChange={(e) => setSelectedCar(e.target.value)}>
                        <option value="">Select Car</option>
                        {cars.map((car) => (
                          <option key={car.id} value={car.id}>
                            {car.car_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="maker">
                      <Form.Label>Select Maker</Form.Label>
                      <Form.Select value={selectedMaker} onChange={(e) => setSelectedMaker(e.target.value)}>
                        <option value="">Select Maker</option>
                        {makers.map((maker) => (
                          <option key={maker.id} value={maker.id}>
                            {maker.maker_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="carModel">
                      <Form.Label>Select Car Model</Form.Label>
                      <Form.Select value={selectedCarModel} onChange={handleCarModelChange}>
                        <option value="">Select Car Model</option>
                        {models.map((model, index) => (
                          <option key={index} value={model.id}>
                            {model.model_name}
                          </option>
                        ))}
                        <option value="other">Other</option>
                        {/* Option for unavailable models */}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="images">
                      <Form.Label>Upload Images</Form.Label>
                      <Form.Control type="file" multiple onChange={handleFileChange} />
                      <div className="preview">
                        {previewUrls.map((url, index) => (
                          <img key={index} src={url} alt="Preview" style={{ width: "100px", height: "100px", margin: "10px" }} />
                        ))}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="videos">
                      <Form.Label>Upload Videos</Form.Label>
                      <Form.Control type="file" accept=".mp4,.avi,.mkv"
                        multiple onChange={handleVideoChange} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="planName">
                      <Form.Label>Plan Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={planName}
                        onChange={(e) => setPlanName(e.target.value)}
                        placeholder="Enter Plan Name"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="planDuration">
                      <Form.Label>Plan Duration</Form.Label>
                      <Form.Control
                        type="text"
                        value={planDuration}
                        onChange={(e) => setPlanDuration(e.target.value)}
                        placeholder="Enter Plan Duration"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="price">
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                        type="text"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        placeholder="Enter Price"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" onClick={handleAddPricing}>
                  Add Pricing
                </Button>
                <Button variant="success" type="submit" className="ml-3">
                  {loading ? "Please wait..." : "Submit"}
                </Button>
              </Form>
              <h2 className="mt-5">Pricing</h2>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Plan Name</th>
                    <th>Plan Duration</th>
                    <th>Price</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {displayPricing.map((price) => (
                    <tr key={price.id}>
                      <td>{price.plan_name}</td>
                      <td>{price.plan_duration}</td>
                      <td>{price.price}</td>
                      <td>
                        <Button variant="warning" onClick={() => handleEditPricing(price)}>
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => handleDeletePricing(price.id)}
                          className="ml-2"
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(pricing.length / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
              <h2 className="mt-5">Uploaded Images</h2>
             <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {images.map((image, index) => (
                    <tr key={index}>
                      <td>
                        <img src={image.url} alt={`Car ${index}`} style={{ width: "100px", height: "100px" }} />
                      </td>
                      <td>
                        <Button variant="danger" onClick={() => handleDeleteImage(image.id)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <h2 className="mt-5">Uploaded Videos</h2>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Video</th>
                  </tr>
                </thead>
                <tbody>
                  {videos.map((video, index) => (
                    <tr key={index}>
                      <td>
                        <video width="200" controls>
                          <source src={video.url} type="video/mp4" />
                          Your browser does not support HTML5 video.
                        </video>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Container>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Pricing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="modalPlanName">
              <Form.Label>Plan Name</Form.Label>
              <Form.Control
                type="text"
                name="planName"
                value={modalData.planName}
                onChange={handleModalChange}
              />
            </Form.Group>
            <Form.Group controlId="modalPlanDuration">
              <Form.Label>Plan Duration</Form.Label>
              <Form.Control
                type="text"
                name="planDuration"
                value={modalData.planDuration}
                onChange={handleModalChange}
              />
            </Form.Group>
            <Form.Group controlId="modalPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="text"
                name="price"
                value={modalData.price}
                onChange={handleModalChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleModalSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for adding new model */}
      <Modal show={showModelManagerModal} onHide={() => setShowModelManagerModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Car Model</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CarModelManager />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModelManagerModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddImages;
