// AddModel.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Table, Modal } from 'react-bootstrap';

const AddModel = () => {
  const [cars, setCars] = useState([]);
  const [makers, setMakers] = useState([]);
  const [selectedCar, setSelectedCar] = useState('');
  const [makerName, setMakerName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editingMaker, setEditingMaker] = useState(null);

  // Fetch car data for dropdown
  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await axios.get('https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars');
        if (response.data && Array.isArray(response.data.cars)) {
          setCars(response.data.cars);
        } else {
          console.error('Unexpected response data format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching car data:', error);
      }
    };

    fetchCars();
  }, []);

  // Fetch makers based on selected car
  useEffect(() => {
    const fetchMakers = async () => {
      if (selectedCar) {
        try {
          const response = await axios.get(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-maker?car_id=${selectedCar}`);
          if (response.data && Array.isArray(response.data.maker)) {
            setMakers(response.data.maker);
          } else {
            console.error('Unexpected response data format:', response.data);
          }
        } catch (error) {
          console.error('Error fetching makers:', error);
        }
      }
    };

    fetchMakers();
  }, [selectedCar]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://phplaravel-1324103-4877027.cloudwaysapps.com/api/store-cars-maker', new URLSearchParams({
        car_id: selectedCar,
        maker_name: makerName
      }));
      alert('Model added successfully');
      // setSelectedCar('');
      setMakerName('');
      // Refresh makers list after adding
      const response = await axios.get(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-maker?car_id=${selectedCar}`);
      if (response.data && Array.isArray(response.data.maker)) {
        setMakers(response.data.maker);
      }
    } catch (error) {
      console.error('Error adding model:', error);
    }
  };

  const handleUpdate = async () => {
    if (editingMaker) {
      try {
        await axios.put(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/update-cars-maker/${editingMaker.id}`, new URLSearchParams({
          car_id: selectedCar,
          maker_name: makerName
        }));
        alert('Maker updated successfully');
        setShowModal(false);
        setEditingMaker(null);
        setMakerName('');
        // Refresh makers list after updating
        const response = await axios.get(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-maker?car_id=${selectedCar}`);
        if (response.data && Array.isArray(response.data.maker)) {
          setMakers(response.data.maker);
        }
      } catch (error) {
        console.error('Error updating maker:', error);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/delete-cars-maker/${id}`);
      alert('Maker deleted successfully');
      // Refresh makers list after deleting
      const response = await axios.get(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-maker?car_id=${selectedCar}`);
      if (response.data && Array.isArray(response.data.maker)) {
        setMakers(response.data.maker);
      }
    } catch (error) {
      console.error('Error deleting maker:', error);
    }
  };

  return (
    <Container className='mt-5 p-0'>
      <h2>Add or Manage Car Models</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formCarSelect">
          <Form.Label>Select Car</Form.Label>
          <Form.Control
            as="select"
            value={selectedCar}
            onChange={(e) => setSelectedCar(e.target.value)}
            required
          >
            <option value="">Select a car</option>
            {cars.map((car) => (
              <option key={car.id} value={car.id}>
                {car.car_name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formMakerName">
          <Form.Label>Model Name</Form.Label>
          <Form.Control
            type="text"
            value={makerName}
            onChange={(e) => setMakerName(e.target.value)}
            required
          />
        </Form.Group>
        <Button className='mt-2 mb-2 ' variant="primary" type="submit">
          Add Model
        </Button>
      </Form>

      {/* <h3 className='mt-5'>Existing Car Models</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Car ID</th>
            <th>Model Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {makers.map((maker) => (
            <tr key={maker.id}>
              <td>{maker.id}</td>
              <td>{maker.car_id}</td>
              <td>{maker.maker_name}</td>
              <td>
                <Button
                  className='btn  me-1' variant="primary"
                  onClick={() => {
                    setEditingMaker(maker);
                    setMakerName(maker.maker_name);
                    setShowModal(true);
                  }}
                >
                  <i class="fa-solid fa-pen-to-square"></i>
                </Button>
                <Button variant="danger" onClick={() => handleDelete(maker.id)}>
                <i class="fa-solid fa-trash"></i>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Maker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editingMaker && (
            <Form>
              <Form.Group controlId="formCarSelect">
                <Form.Label>Select Car</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedCar}
                  onChange={(e) => setSelectedCar(e.target.value)}
                  required
                >
                  <option value="">Select a car</option>
                  {cars.map((car) => (
                    <option key={car.id} value={car.id}>
                      {car.car_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formMakerName">
                <Form.Label>Model Name</Form.Label>
                <Form.Control
                  type="text"
                  value={makerName}
                  onChange={(e) => setMakerName(e.target.value)}
                  required
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}
    </Container>
  );
};

export default AddModel;
