import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getVehicleDetailsById } from "../../../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CompleteOrder.css";

const CompleteOrder = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const doc = await getVehicleDetailsById(id);
                if (doc) { // Check if doc is not null
                    setData(doc);
                } else {
                    console.error("No such document!");
                    toast.error("No data found.");
                }
            } catch (error) {
                console.error("Error fetching document:", error);
                toast.error("Error fetching data.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!data) {
        return <div>No data found.</div>;
    }

    return (
        <div>
            <h1>Complete Order</h1>
            <p>Order ID: {id}</p>

            <h2>Vehicle Details</h2>
            <table>
                <thead>
                    <tr>
                        <th>Field</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Car Registration</td>
                        <td>{data.stepOneData?.carRegistration || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Make</td>
                        <td>{data.stepOneData?.selectedCar || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Model</td>
                        <td>{data.stepOneData?.selectedMaker || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>MOT Expiry Date</td>
                        <td>{data.stepOneData?.motExpiryDate || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Current Mileage</td>
                        <td>{data.stepOneData?.estimatedMileage || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Last Service Date</td>
                        <td>{data.stepOneData?.firstRegistrationDate || 'N/A'}</td>
                    </tr>
                </tbody>
            </table>

            <h2>Personal Details</h2>
            <table>
                <thead>
                    <tr>
                        <th>Field</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>First Name</td>
                        <td>{data.stepTwoData?.fname || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Surname</td>
                        <td>{data.stepTwoData?.lastname || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Phone Number</td>
                        <td>{data.stepTwoData?.phone || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{data.stepTwoData?.email || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Buy Date</td>
                        <td>{data.stepTwoData?.buydate || 'N/A'}</td>
                    </tr>
                </tbody>
            </table>

            <ToastContainer />
        </div>
    );
};

export default CompleteOrder;
