import React, { useEffect } from "react";
import { fetchReviewDetails } from "../../../firebase";
import Sidebar from "../../../Components/Sidebar";
import AdminHeader from "../../../Components/AdminHeader";
import Footer from "../../../Components/Footer";
function CommentsData() {
  const [comments, setComments] = React.useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchReviewDetails();
      setComments(data);
    };
    fetchData();
  }, []);

  console.log("comments are", comments);
  return (
    <>
    <AdminHeader/>
      <div className="container-fluid p-0">
        <div className="row">
          <Sidebar />
          <div className="col-md-9">
            <div className="container">
            <h2 className="text-center fw-bold">Comments Data</h2>  
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                {comments.map((comment) => (
                  <tr key={comment.id}>
                    <td>{comment.UserName}</td>
                    <td>{comment.Comment}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
       
          </div>
        </div>
      </div>
    </>
  );
}

export default CommentsData;
