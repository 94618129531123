import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../Components/TopHeader";
import MainHeader from "../../Components/MainHeader";
import Footer from "../../Components/Footer";
import "./single.css";
import { addContactDetails, addReviewDetails } from "../../firebase";

const ProductSingle = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCars = [] } = location.state || {}; // Default to empty array

  console.log("selected car is ",selectedCars)

  const [cartItems, setCartItems] = useState(selectedCars);
  const [mainMedia, setMainMedia] = useState(
    selectedCars.length > 0
      ? selectedCars[0]?.images?.[0]?.url || selectedCars[0]?.videos?.[0]?.url || ""
      : ""
  );
  const [isVideo, setIsVideo] = useState(
    selectedCars.length > 0 ? selectedCars[0]?.videos?.length > 0 : false
  );

  const calculateTotals = () => {
    const totalPrice = cartItems.reduce((sum, item) => sum + item.totalPrice, 0);
    const discount = 50; // Example fixed discount, replace with actual logic
    const total = totalPrice - discount;
    return { totalPrice, discount, total };
  };

  const { totalPrice, discount, total } = calculateTotals();

  useEffect(() => {
    if (selectedCars.length > 0) {
      const firstCar = selectedCars[0];
      setMainMedia(firstCar?.images?.[0]?.url || firstCar?.videos?.[0]?.url || "");
      setIsVideo(firstCar?.videos?.length > 0 && !firstCar?.images?.length);
    }
  }, [selectedCars]);

  const handleMediaClick = (src, isVideo) => {
    setMainMedia(src);
    setIsVideo(isVideo);
  };

  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [reviewForm, setReviewForm] = useState({
    UserName: "",
    Comment: "",
  });

  const [loading, setLoading] = useState(false);
  const [reviewLoading, setReviewLoading] = useState(false);

  const [formStatus, setFormStatus] = useState("");
  const [reviewformStatus, setReviewFormStatus] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    setReviewForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await addContactDetails(contactForm);
      setFormStatus("Thanks for contacting us.");
      setContactForm({ name: "", email: "", message: "" }); // Reset form
    } catch (error) {
      setFormStatus("Error sending message. Please try again.");
      console.error("Error adding document: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    try {
      setReviewLoading(true);
      await addReviewDetails(reviewForm);
      setReviewFormStatus("Thanks for commenting.");
      setReviewForm({ UserName: "", Comment: "" }); // Reset form
    } catch (error) {
      setReviewFormStatus("Error adding comment. Please try again.");
      console.error("Error adding document: ", error);
    } finally {
      setReviewLoading(false);
    }
  };

  const handleCheckout = () => {
    navigate("/checkout", {
      state: {
        cartItems,
        totalPrice,
        discount,
        total,
      },
    });
  };

  return (
    <>
      <TopHeader />
      <MainHeader />
      <section className="py-5">
        <div className="container">
          <div className="row gx-5">
            <aside className="col-lg-6">
              <div className="border rounded-4 mb-3 d-flex justify-content-center overflow-hidden">
                <a
                  data-fslightbox="mygalley"
                  className="rounded-4"
                  data-type="image"
                  href={mainMedia}
                >
                  {isVideo ? (
                    <video
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100vh",
                        margin: "auto",
                      }}
                      className="rounded-4 fit main_image_single"
                      src={mainMedia}
                      controls
                      alt="Product Video"
                    />
                  ) : (
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100vh",
                        margin: "auto",
                      }}
                      className="rounded-4 fit main_image_single"
                      src={mainMedia}
                      alt="Product"
                    />
                  )}
                </a>
              </div>
              <div className="d-flex justify-content-start mb-3">
                {cartItems[0]?.images?.map((img, index) => (
                  <a
                    key={`img-${index}`}
                    data-fslightbox="mygalley"
                    className="border mx-1 rounded-2"
                    target="_blank"
                    data-type="image"
                    href={img.url}
                    onClick={(e) => {
                      e.preventDefault();
                      handleMediaClick(img.url, false);
                    }}
                  >
                    <img
                      width={70}
                      height={70}
                      className="rounded-2"
                      src={img.url}
                      alt={`Thumbnail ${index + 1}`}
                    />
                  </a>
                ))}
                {cartItems[0]?.videos?.map((vid, index) => (
                  <a
                    key={`vid-${index}`}
                    data-fslightbox="mygalley"
                    className="border mx-1 rounded-2"
                    target="_blank"
                    data-type="video"
                    href={vid.url}
                    onClick={(e) => {
                      e.preventDefault();
                      handleMediaClick(vid.url, true);
                    }}
                  >
                    <video
                      width={70}
                      height={70}
                      className="rounded-2"
                      src={vid.url}
                      alt={`Video Thumbnail ${index + 1}`}
                    />
                  </a>
                ))}
              </div>
            </aside>
            <main className="col-lg-6">
              {cartItems.map((item) => (
                <div className="ps-lg-3" key={item.id}>
                  <h4 className="title text-dark">
                    {item.modelName || "Model Name"}
                  </h4>
                  <p className="text-secondary">{item.headTitle || "Title"}</p>
                  <div className="my-3 text-muted">
                    <span className="h5">{item.name || "Plan Name"}: </span>
                    <span className="h5">${item.totalPrice || "75.00"}</span>
                  </div>
                  <p>{item.productDescription || "Description"}</p>
                  <div className="row">
                    <dt className="col-3">Type:</dt>
                    <dd className="col-9">{item.carType || "N/A"}</dd>
                    <dt className="col-3">Service Type</dt>
                    <dd className="col-9">{item.serviceType || "N/A"}</dd>
                    <dt className="col-3">Engine</dt>
                    <dd className="col-9">{item.engineCapacity || "N/A"}</dd>
                  </div>
                  <hr />
                  <button
                    onClick={handleCheckout}
                    className="btn btn-primary shadow-0"
                  >
                    {" "}
                    <i className="me-1 fa fa-shopping-basket" /> Buy Now{" "}
                  </button>
                </div>
              ))}
            </main>
          </div>
        </div>
      </section>

      <section className="bg-light border-top py-4">
        <div className="container">
          <div className="row gx-4">
            <div className="col-lg-8 mb-4">
              <div className="border rounded-2 px-3 py-2 bg-white">
                <div className="tab-content" id="ex1-content">
                  <h2 className="text-center fw-bold ">Description</h2>
                  {cartItems.map((item) => (
                  <div
                    className="tab-pane fade show active"
                    id="ex1-pills-1"
                    role="tabpanel"
                    aria-labelledby="ex1-tab-1"
                  >
                    <p>
                    {item.productDescription || "description"}
                    </p>
                    <div className="row mb-2">
                      <div className="col-12 col-md-6">
                        <ul className="list-unstyled mb-0">
                          <li>
                            <i className="fas fa-check text-success me-2" />
                            Some great feature name here
                          </li>
                          <li>
                            <i className="fas fa-check text-success me-2" />
                            Lorem ipsum dolor sit amet, consectetur
                          </li>
                          <li>
                            <i className="fas fa-check text-success me-2" />
                            Duis aute irure dolor in reprehenderit
                          </li>
                          <li>
                            <i className="fas fa-check text-success me-2" />
                            Optical heart sensor
                          </li>
                        </ul>
                      </div>
                      <div className="col-12 col-md-6 mb-0">
                        <ul className="list-unstyled">
                          <li>
                            <i className="fas fa-check text-success me-2" />
                            Easy fast and ver good
                          </li>
                          <li>
                            <i className="fas fa-check text-success me-2" />
                            Some great feature name here
                          </li>
                          <li>
                            <i className="fas fa-check text-success me-2" />
                            Modern style and design
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  ))}
                </div>
              </div>
              <div className="col-12 col-md-12 my-5">
                <h2>Leave a comment </h2>
                <form onSubmit={handleReviewSubmit}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      id="UserName"
                      name="UserName"
                      className="form-control"
                      value={reviewForm.UserName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                      Comment
                    </label>
                    <textarea
                      id="Comment"
                      name="Comment"
                      className="form-control"
                      rows="3"
                      value={reviewForm.Comment}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  {reviewformStatus && (
                    <p className="mt-2 text-success fw-bold">{reviewformStatus}</p>
                  )}
                  <button
                    type="submit"
                    className="btn btn-dark"
                    disabled={reviewLoading}
                  >
                    Comment
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="px-0 border rounded-2 shadow-0">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Contact Us</h5>
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          value={contactForm.name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          value={contactForm.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="message" className="form-label">
                          Message
                        </label>
                        <textarea
                          id="message"
                          name="message"
                          className="form-control"
                          rows="3"
                          value={contactForm.message}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                      >
                        Send
                      </button>
                    </form>
                    {formStatus && (
                      <p className="mt-2 text-success fw-bold">{formStatus}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ProductSingle;
