import React from "react";
import credit from "../../assets/images/credit-card.png";
import paypal from "../../assets/images/paypal.png";
import "./footer.css"

// import logo from "../../assets/images/Serve Biznes logo.png";

const Footer = () => {
  return (
    <>
      {/* Remove the container if you want to extend the Footer to full width. */}
      <div className="container-fluid p-0 mt-5">
        {/* Footer */}
        <footer
          className="text-center text-lg-start text-white footer_main_org p-0"
          style={{ backgroundColor: "#3e4551" }}
        >
          {/* Grid container */}
          <div className="container-fluid px-4 helpful_boxs">
            <div className="row py-3 py-md-5 pb-0 pb-md-5">
              <div className="col-md-4 mb-4 mb-md-0 d-flex gap-4 align-items-center">
                <div className="quote_icon">
                  <i class="fa-regular fa-envelope"></i>
                </div>
                <div className="quote_des">
                  <h4 className="mb-0">Get a Quote Now</h4>
                  <p className="mb-0">send us a email</p>
                </div>
              </div>
              <div className="col-md-4 mb-4 mb-md-0 d-flex gap-4 align-items-center">
                <div className="quote_icon">
                  <i class="fa-solid fa-headset"></i>
                </div>
                <div className="quote_des">
                  <h4 className="mb-0">Call (123) 456-7890</h4>
                  <p className="mb-0">Mon-Fri: 9 am to 19 pm</p>
                </div>
              </div>
              <div className="col-md-2 mb-4 mb-md-0 d-flex gap-4 align-items-center">
                <div className="quote_icon">
                  <i class="fa-solid fa-truck"></i>
                </div>
                <div className="quote_des">
                  <h4 className="mb-0">Delivery</h4>
                  <p className="mb-0">Free and Safe</p>
                </div>
              </div>
              <div className="col-md-2 mb-4 mb-md-0 d-flex gap-3 align-items-center footer-social">
                <a href="#">
                  <i className="fa-brands fa-facebook"></i>
                </a>
                <a href="#">
                  <i className="fa-brands fa-linkedin"></i>
                </a>
                <a href="#">
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fa-brands fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="container-fluid px-4 py-3 pt-md-5 pb-0 footer-items">
            {/* Section: Links */}
            <section className="">
              {/*Grid row*/}
              <div className="row">
                {/*Grid column*/}
                <div className="col-lg-4 col-md-6 mb-4 mb-md-0 text-start">
                  <h5 className="text-uppercase">About US</h5>
                  <p className="pt-2 about_des">
                    Big Dreams Start Small Live Your Life Beautifully. Car
                    insurance helps to protect you against financial loss in the
                    event of an accident, theft or damage to your car. Auto
                    insur- ance covers the injuries and damages.
                  </p>
                </div>
                {/*Grid column*/}
                {/*Grid column*/}
                <div className="col-lg-2 col-md-6 mb-4 mb-md-0 text-start">
                  <h5 className="text-uppercase">Contact</h5>
                  <ul className="list-unstyled mb-0 footer_lists">
                    <li>
                      <i class="fa-solid fa-location-dot" className="me-2"></i>
                      <a href="#!" className="text-white">
                        123 Motor St, Car City
                      </a>
                    </li>
                    <li>
                      <i class="fa-solid fa-location-dot" className="me-2"></i>
                      <a href="tel:(123) 456-7890" className="text-white">
                        (123) 456-7890
                      </a>
                    </li>
                    <li>
                      <i class="fa-solid fa-location-dot" className="me-2"></i>
                      <a
                        href="mailto: info@motoreasy.com"
                        className="text-white"
                      >
                        info@motoreasy.com
                      </a>
                    </li>
                  </ul>
                </div>
                {/*Grid column*/}
                {/*Grid column*/}
                <div className="col-lg-2 col-md-6 mb-4 mb-md-0 text-start">
                  <h5 className="text-uppercase">About</h5>
                  <ul className="list-unstyled mb-0 footer_lists">
                    <li>
                      <a href="/About-us">About Us</a>
                    </li>
                    <li>
                      <a href="contact-us">Contact Us</a>
                    </li>
                    <li>
                      <a href="/Blogs">Blog</a>
                    </li>
                  </ul>
                </div>
                {/*Grid column*/}
                {/*Grid column*/}
                <div className="col-lg-2 col-md-6 mb-4 mb-md-0 text-start">
                  <h5 className="text-uppercase">Services</h5>
                  <ul className="list-unstyled mb-0 footer_lists">
                    <li>
                      <a href="/Used-Car-Warrenty">Used Car Warranty</a>
                    </li>
                    <li>
                      <a href="/Vehicle-inspections">Vehicle Inspection</a>
                    </li>
                    <li>
                      <a href="Vehicle-Service">Vehicle Service</a>
                    </li>
                    <li>
                      <a href="/Road-side">Road Side</a>
                    </li>
                    <li>
                      <a href="/Battery-Replacement">Battery Replacement</a>
                    </li>
                    <li>
                      <a href="/Wind-screen-Repair">Windscreen Repair</a>
                    </li>
                  </ul>
                </div>
                {/*Grid column*/}
                {/*Grid column*/}
                <div className="col-lg-2 col-md-6 mb-4 mb-md-0 text-start">
                  <h5 className="text-uppercase">Pages</h5>
                  <ul className="list-unstyled mb-0 footer_lists">
                    <li>
                      <a href="/FAQ-page">FAQ</a>
                    </li>
                    <li>
                      <a href="/testominals">Testimonial</a>
                    </li>
                    <li>
                      <a href="/Pricing">Pricing</a>
                    </li>
                  </ul>
                </div>
                {/*Grid column*/}
              </div>
              {/*Grid row*/}
            </section>
            {/* Section: Links */}
            <hr className="mb-4" />
            {/* Section: CTA */}
            <section className="">
              <div className="row d-flex align-items-center gap-3 gap-md-0">
                <div className="col-md-6">
                  <div className="email-form">
                    <h4>Get our latest news and special sales</h4>
                    <form action="" className="d-flex w-100">
                      <input
                        type="email"
                        className=" w-75"
                        placeholder="enter your email"
                        required
                      />
                      <button
                        type="submit"
                        className="w-25 btn btn-info rounded-0 text-light fw-light fw-md-bold"
                      >
                        Subscribe
                      </button>
                    </form>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="payment-options d-flex justify-content-center justify-content-md-end gap-4 mt-0 mt-md-4">
                    <img src={credit} width={40} alt="" />
                    <img src={paypal} width={40} alt="" />
                  </div>
                </div>
              </div>
            </section>
            
            {/* Section: CTA */}
          </div>
          <section className="copy-right py-4 mt-3 bg-dark">
            <div className="text-center text-light fw-normal text-capitalize">
            © 2024 Motor Easy. All rights reserved
            </div>
          </section>
        </footer>
        {/* Footer */}
      </div>
      {/* End of .container */}
    </>
  );
};

export default Footer;
