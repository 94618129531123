// CarTable.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Container, Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AddModel from '../AddModel';
import CarModelManager from '../CarModelManager';

const CarTable = () => {
  const [cars, setCars] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingCar, setEditingCar] = useState(null);
  const [newCarName, setNewCarName] = useState('');

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await axios.get('https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars');
        if (response.data && Array.isArray(response.data.cars)) {
          setCars(response.data.cars);
        } else {
          toast.error('Unexpected response data format:', response.data);
        }
      } catch (error) {
        toast.error('Error fetching car data:', error);
      }
    };

    fetchCars();
  }, []);

  const handleUpdate = async () => {
    if (editingCar) {
      try {
        await axios.put(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/update-cars/${editingCar.id}`, new URLSearchParams({ car_name: newCarName }));
        // Refresh the list after updating
        const response = await axios.get('https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars');
        if (response.data && Array.isArray(response.data.cars)) {
          setCars(response.data.cars);
          setShowModal(false); // Close the modal
          setEditingCar(null);
          setNewCarName('');
        }
        toast.success('data updated !!')
      } catch (error) {
        toast.error('Error updating car data:', error);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/delete-cars/${id}`);
      // Refresh the list after deleting
      const response = await axios.get('https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars');
      if (response.data && Array.isArray(response.data.cars)) {
        setCars(response.data.cars);
      }
      toast.success('data deleted successfully')
    } catch (error) {
      toast.error('Error deleting car data:', error);
    }
  };

  return (
    <>
    <Container>
      {/* <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Car Name</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(cars) && cars.map((car) => (
            <tr key={car.id}>
              <td>{car.id}</td>
              <td>{car.car_name}</td>
              <td>{new Date(car.created_at).toLocaleString()}</td>
              <td>{new Date(car.updated_at).toLocaleString()}</td>
              <td>
                <Button className='me-1'
                  variant="primary"
                  onClick={() => {
                    setEditingCar(car);
                    setNewCarName(car.car_name);
                    setShowModal(true);
                  }}
                >
                  <i class="fa-solid fa-pen-to-square"></i>
                </Button >
                <Button variant="danger" onClick={() => handleDelete(car.id)}>
                <i class="fa-solid fa-trash"></i>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Car</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editingCar && (
            <Form>
              <Form.Group controlId="formCarName">
                <Form.Label>Car Name</Form.Label>
                <Form.Control
                  type="text"
                  value={newCarName}
                  onChange={(e) => setNewCarName(e.target.value)}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}
    </Container>

    <hr/>
    <Container>
      <AddModel/>
    </Container>
    <hr/>
    <Container>
      <CarModelManager/>
    </Container>
    </>
  );
};

export default CarTable;
