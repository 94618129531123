import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./header_index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Basket from "../Basket";
import { RiShoppingBasketFill } from "react-icons/ri";
import { StepContext } from "../../Context/StepContext";
import { Spinner } from "react-bootstrap";

const MainHeader = () => {
  const { currentUser, signOut } = useAuth();
  const [loggingOut, setLoggingOut] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const { cart, loading } = useContext(StepContext);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const navigate = useNavigate();

  const handleLogout = async () => {
    setLoggingOut(true);
    try {
      await signOut();
      toast.success("You are now logged out");
      setTimeout(() => {
        navigate("/Sign_In");
      }, 1000);
    } catch (error) {
      console.error("Failed to log out", error);
      toast.error("Failed to log out. Please try again later.");
    } finally {
      setLoggingOut(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="second_header container sticky-top top-0">
        <div className="container set_max_width_banner">
          <header>
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <button
                  className="navbar-toggler"
                  type="button"
                  aria-controls="navbarSupportedContent"
                  aria-expanded={!isNavCollapsed ? true : false}
                  aria-label="Toggle navigation"
                  onClick={handleNavCollapse}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
                  <ul className="navbar-nav gap-0 gap-md-3 text-light mb-2 mb-lg-0 align-items-center">
                    <li className="nav-item">
                      <Link className="nav-link" to="/" onClick={handleNavCollapse}>
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/About-us" onClick={handleNavCollapse}>
                        About Us
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Services
                      </a>
                      <ul className="dropdown-menu">
                        <li className="dropdown-link">
                          <Link className="dropdown-item" to="/Used-Car-Warrenty" onClick={handleNavCollapse}>
                            Used Car Warranty
                          </Link>
                        </li>
                        <li className="dropdown-link">
                          <Link className="dropdown-item" to="/Vehicle-inspections" onClick={handleNavCollapse}>
                            Vehicle Inspection
                          </Link>
                        </li>
                        <li className="dropdown-link">
                          <Link className="dropdown-item" to="/Vehicle-service" onClick={handleNavCollapse}>
                            Vehicle Service
                          </Link>
                        </li>
                        <li className="dropdown-link">
                          <Link className="dropdown-item" to="/Road-side" onClick={handleNavCollapse}>
                            Road Side
                          </Link>
                        </li>
                        <li className="dropdown-link">
                          <Link className="dropdown-item" to="/Battery-Replacement" onClick={handleNavCollapse}>
                            Battery Replacement
                          </Link>
                        </li>
                        <li className="dropdown-link">
                          <Link className="dropdown-item" to="/wind-screen-Repair" onClick={handleNavCollapse}>
                            Windscreen Repair
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Pages
                      </a>
                      <ul className="dropdown-menu">
                        <li className="dropdown-link">
                          <Link className="dropdown-item" to="/Faq-Page" onClick={handleNavCollapse}>
                            FAQ
                          </Link>
                        </li>
                        <li className="dropdown-link">
                          <Link className="dropdown-item" to="/Testominals" onClick={handleNavCollapse}>
                            Testimonial
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/Pricing" onClick={handleNavCollapse}>
                        Pricing
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/Blogs" onClick={handleNavCollapse}>
                        Blog
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/Contact-us" onClick={handleNavCollapse}>
                        Contact Us
                      </Link>
                    </li>
                    <li
                      className="nav-link text-light"
                      style={{ "--i": "1.35s" }}
                    >
                      <i className="fa fa-search search-icon"></i>
                    </li>
                  </ul>
                </div>
                <div className="log-sign navbar-nav">
                  {loading ? (
                    <button className="btn border-0 basket_btn">
                      <Spinner />
                    </button>
                  ) : (
                    <button className="btn border-0 basket_btn" onClick={handleShowModal}>
                      <RiShoppingBasketFill />
                      {cart.length > 0 && (
                        <span className="badge bg-danger rounded-circle">
                          {cart.length}
                        </span>
                      )}
                    </button>
                  )}

                  {currentUser ? (
                    <div className="dropdown">
                      <button
                        className="btn btn-dark user-btn"
                        style={{ backgroundColor: "#003a63" }}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {currentUser.email.split("@")[0]}{" "}
                        <i className="fa fa-user ms-2 bg-dark p-2 rounded-circle"></i>
                      </button>
                      <ul className="dropdown-menu user-dropdown-menu">
                        <li className="dropdown-link">
                          <Link className="dropdown-item" onClick={handleLogout}>
                            {loggingOut ? "Logging out..." : "Log out"}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <>
                      <Link to="/Sign_in">
                        <button className="btn-transparent">Log in</button>
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </nav>
          </header>
        </div>
      </div>
      <Basket show={showModal} handleClose={handleCloseModal} />
    </>
  );
};

export default MainHeader;
