import {
  initializeApp
} from "firebase/app";
import {
  getAuth
} from "firebase/auth";
import {
  getStorage
} from "firebase/storage";
import {
  getFirestore,
  addDoc,
  collection,
  query,
  getDocs,
  doc,
  getDoc,
  setDoc
} from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBYRl7Pcr9xT-VPMQ5503F3UF5K18YSJA0",
  authDomain: "motor-easy-99969.firebaseapp.com",
  projectId: "motor-easy-99969",
  storageBucket: "motor-easy-99969.appspot.com",
  messagingSenderId: "716031045861",
  appId: "1:716031045861:web:8d5ada8a59e6d337c9640c",
  measurementId: "G-HGMBQ254H5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export {
  app,
  auth,
  storage,
  db
};

// Function to add vehicle details
export const addVehicleDetails = async (data) => {
  try {
    const docRef = await addDoc(collection(db, 'vehicleDetails'), data);
    console.log('Document written with ID: ', docRef.id);
    return docRef;
  } catch (e) {
    console.error('Error adding document: ', e);
    throw new Error('Error adding document');
  }
};

// Function to fetch all vehicle details
export const fetchVehicleDetails = async () => {
  try {
    const q = query(collection(db, 'vehicleDetails'));
    const querySnapshot = await getDocs(q);
    const vehicleDetails = [];
    querySnapshot.forEach((doc) => {
      vehicleDetails.push({
        id: doc.id,
        ...doc.data()
      });
    });
    console.log('Vehicle Details: ', vehicleDetails);
    return vehicleDetails;
  } catch (e) {
    console.error('Error fetching vehicle details: ', e);
    throw new Error('Error fetching vehicle details');
  }
};

// Function to get vehicle details by ID
export const getVehicleDetailsById = async (id) => {
  try {
    const docRef = doc(db, 'vehicleDetails', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      return {
        id: docSnap.id,
        ...docSnap.data()
      };
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (e) {
    console.error('Error fetching document:', e);
    throw new Error('Error fetching document');
  }
};

// Function to add model details
export const addModelDetails = async (model) => {
  try {
    const docRef = await addDoc(collection(db, 'selectedModel'), {
      model
    });
    console.log('Model submitted: ', model);
    return docRef.id;
  } catch (error) {
    console.error('Error adding document: ', error);
    throw new Error('Failed to add model details');
  }
};

export const addContactDetails = async (data) => {
  try {
    const docRef = await addDoc(collection(db, 'contactDetails'), {
      data
    });
    console.log('Model submitted: ', data);
    return docRef.id;

  } catch (error) {
    throw new Error("Error adding document: " + error.message);
  }
};

export const addReviewDetails = async (data) => {
  try {
    const docRef = await addDoc(collection(db, 'ReviewDetails', data))

    console.log('Review submitted: ', data);
    return docRef.id;
    
  } catch (error) {
    throw new Error("Error adding review: " + error.message);
  }
};

export const fetchReviewDetails = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "ReviewDetails"));
    const documents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    return documents;
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};