import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import { StepContext } from '../../Context/StepContext';
import { FaTrash } from 'react-icons/fa';

const Basket = ({ show, handleClose }) => {
  const { cart, removeItemFromCart } = useContext(StepContext);

  const handleRemoveItem = async (itemId) => {
    removeItemFromCart(itemId);
  };


  return (
    <Modal show={show} onHide={handleClose} size='lg' backdrop="static">
    <Modal.Header closeButton>
      <Modal.Title>Cart Items</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {cart.length > 0 ? (
        <ListGroup>
          {cart.map((item, index) => (
            <ListGroup.Item key={index} style={{ padding: '10px' }}>
              <div className="Date_Info">
                <div className="row w-100 p-0">
                  <div className="col-md-4">
                    <h6>Booking Date: {item.bookingDate}</h6>
                  </div>
                  <div className="col-md-4 text-center">
                    <h6>PostCode: {item.postcode}</h6>
                  </div>
                  <div className="col-md-4 text-end">
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleRemoveItem(item.id)}
                      title='remove item from cart'
                    >
                      <FaTrash />
                    </Button>
                  </div>
                  <div className="col-md-4">
                    <h6>MOT Expiry_Date: {item.motExpiryDate}</h6>
                  </div>
                  <div className="col-md-4 text-center">
                    <h6>Reg_Date: {item.firstRegistrationDate}</h6>
                  </div>
                  <div className="col-md-4 text-center">
                    <h6>Modal: {item.Model}</h6>
                  </div>
                </div>
              </div>
              <div className="row personal_info d-flex align-items-center justify-content-between">
                <div className="col-md-4">
                  <p>{item.fname} {item.lname}</p>
                </div>
                <div className="col-md-4 text-center">
                  <p>{item.phone}</p>
                </div>
                <div className="col-md-4 text-center">
                  <p><b>RegN:</b> {item.carNumber}</p>
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <p>No items in the cart.</p>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default Basket;
