import React from "react";

const DeleteImageButton = ({ onClick }) => {
  return (
    <button
      type="button"
      className="btn position-absolute d-flex align-items-center justify-content-center"
      style={{
        top: "2px",
        left: "65px",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        border: "none",
        borderRadius: "50%",
        width: "24px",
        height: "24px",
        color: "white",
        fontSize: "16px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <i className="fa fa-times"></i>
    </button>
  );
};

export default DeleteImageButton;
