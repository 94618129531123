import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import MainHeader from "../../Components/MainHeader";
import creditCard from "../../assets/images/credit-card.png";
import paypal from "../../assets/images/paypal.png";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { sendEmail } from "../../emailService";

const Checkout = () => {
  const location = useLocation();
  const { cartItems = [], totalPrice = 0, discount = 0, total = 0 } = location.state || {};
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    address: '',
    address2: '',
    country: '',
    state: '',
    zip: '',
    carNumber: '',
    model: '',
    regDate: '',
    motExpDate: '',
    phoneNumber: ''
  });

  const [showModal, setShowModal] = useState(false);
  const [cardDetails, setCardDetails] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
  });

  const handleChange = (field, value) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleCardChange = (field, value) => {
    setCardDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const validateFields = () => {
    const fields = {
      name: "Name",
      email: "Email",
      address: "Address",
      country: "Country",
      state: "State",
      zip: "Postal Code",
      carNumber: "Car Number",
      model: "Model",
      regDate: "Reg Date",
      phoneNumber: "Phone Number",
    };

    for (let field in fields) {
      if (!userDetails[field]) {
        toast.error(`Please enter ${fields[field]}.`);
        return false;
      }
    }

    if (!cardDetails.cardNumber) {
      toast.error("Please enter your card number.");
      return false;
    }

    if (!cardDetails.expiryDate) {
      toast.error("Please enter the card expiry date.");
      return false;
    }

    if (!cardDetails.cvv) {
      toast.error("Please enter the CVV.");
      return false;
    }

    return true;
  };

  const handlePayment = () => {
    if (!validateFields()) {
      return;
    }

    generateAndSendInvoice();
    setShowModal(false);
  };

  const generateAndSendInvoice = async () => {
    const safeUserDetails = {
      name: userDetails.name || "N/A",
      email: userDetails.email || "N/A",
      address: userDetails.address || "N/A",
      address2: userDetails.address2 || "N/A",
      country: userDetails.country || "N/A",
      state: userDetails.state || "N/A",
      zip: userDetails.zip || "N/A",
      carNumber: userDetails.carNumber || "N/A",
      model: userDetails.model || "N/A",
      regDate: userDetails.regDate || "N/A",
      motExpDate: userDetails.motExpDate || "N/A",
      phoneNumber: userDetails.phoneNumber || "N/A"
    };
  
    const safeCartItems = cartItems.map((item) => ({
      name: item.name || "Unnamed Product",
      quantity: item.quantity || 0,
      plan: {
        price: item.plan?.price || 0,
      },
      totalPrice: item.totalPrice || 0,
    }));
  
    const doc = new jsPDF();
  
    doc.setFontSize(18);
    doc.text("Motor Easy Invoice", 14, 22);
  
    doc.setFontSize(12);
    doc.text(`Name: ${safeUserDetails.name}`, 14, 30);
    doc.text(`Email: ${safeUserDetails.email}`, 14, 36);
    doc.text(
      `Address: ${safeUserDetails.address}, ${safeUserDetails.address2}`,
      14,
      42
    );
    doc.text(`Country: ${safeUserDetails.country}`, 14, 48);
    doc.text(`State: ${safeUserDetails.state}`, 14, 54);
    doc.text(`ZIP: ${safeUserDetails.zip}`, 14, 60);
    doc.text(`Car Number: ${safeUserDetails.carNumber}`, 14, 66);
    doc.text(`Model: ${safeUserDetails.model}`, 14, 72);
    doc.text(`Reg Date: ${safeUserDetails.regDate}`, 14, 78);
    doc.text(`MOT Exp Date: ${safeUserDetails.motExpDate}`, 14, 84);
    doc.text(`Phone Number: ${safeUserDetails.phoneNumber}`, 14, 90);
    doc.text("Invoice Date: " + new Date().toLocaleDateString(), 14, 96);
  
    const columns = ["Product", "Quantity", "Price per Unit", "Total"];
    const rows = safeCartItems.map((item) => [
      item.name,
      item.quantity,
      `$${parseFloat(item.plan.price).toFixed(2)}`,
      `$${item.totalPrice.toFixed(2)}`,
    ]);
  
    doc.autoTable({
      startY: 100,
      head: [columns],
      body: rows,
    });
  
    const finalY = doc.lastAutoTable.finalY;
    doc.text(`Total Price: $${totalPrice.toFixed(2)}`, 14, finalY + 10);
    doc.text(`Discount: $${discount.toFixed(2)}`, 14, finalY + 16);
    doc.text(`Total: $${total.toFixed(2)}`, 14, finalY + 22);
  
    doc.setFontSize(10);
    doc.text(
      "Thank you for choosing Motor Easy! We offer a wide range of services.\n" +
        "Please refer to our service policy for more details. All services are performed\n" +
        "by certified technicians and include a 30-day satisfaction guarantee.",
      14,
      finalY + 32
    );
  
    const pdfBase64 = btoa(doc.output());
  
    const invoiceData = {
      userDetails: safeUserDetails,
      cartItems: safeCartItems,
      totalPrice: totalPrice || 0,
      discount: discount || 0,
      total: total || 0,
      pdfBase64,
      date: new Date().toLocaleDateString(),
    };
  
    try {
      const docRef = await addDoc(collection(db, "Orders"), invoiceData);
      toast.success("Please check email for invoice");
      console.log("Order Document written with ID: ", docRef.id);
      await sendEmail(invoiceData, safeUserDetails);
    } catch (error) {
      console.error("Error saving invoice to Firestore:", error);
    } finally {
      navigate("/");
    }
  };
  
  return (
    <>
      <MainHeader />
      <main>
        <div className="container mb-4">
          <div className="py-5 text-center">
            <h2>Checkout Summary</h2>
            <p className="lead">
              Please review your invoice details and complete your purchase.
            </p>
          </div>
          <div className="row">
            <div className="col-md-5 order-md-2 mb-4">
              <div>
                <h4 className="d-flex justify-content-between align-items-center mb-3">
                  <span className="text-muted">Your cart</span>
                  <span className="badge badge-secondary badge-pill">
                    {cartItems.length}
                  </span>
                </h4>
                <ul className="list-group mb-3">
                  {cartItems.map((item, index) => (
                    <li
                      key={index}
                      className="list-group-item d-flex justify-content-between lh-condensed"
                    >
                      <div>
                        <h6 className="my-0">{item.name}</h6>
                        <small className="text-muted">
                          {item.carType || "N/A"}
                        </small>
                      </div>
                      <span className="text-muted">
                        ${item.totalPrice.toFixed(2)}
                      </span>
                    </li>
                  ))}
                  <li className="list-group-item d-flex justify-content-between">
                    <span>Total (USD)</span>
                    <strong>${total.toFixed(2)}</strong>
                  </li>
                </ul>
              </div>
              <div className="shadow p-3">
                <h4 className="mb-3 text-center">Payment</h4>
                <div className="d-block my-3">
                  <div className="custom-control custom-radio d-flex align-items-center gap-2 bg-secondary text-light p-2 rounded-4 mb-2">
                    <input
                      id="credit"
                      name="paymentMethod"
                      type="radio"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <label
                      className="custom-control-label mb-0"
                      htmlFor="credit"
                    >
                      <img src={creditCard} width={40} alt="" /> Credit card
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-flex align-items-center gap-2 text-dark p-2 rounded-4 mb-2">
                    <input
                      id="paypal"
                      name="paymentMethod"
                      type="radio"
                      className="custom-control-input"
                    />
                    <label
                      className="custom-control-label mb-0"
                      htmlFor="paypal"
                    >
                      <img src={paypal} width={40} alt="" />
                      Paypal
                    </label>
                  </div>
                </div>
                <button
                  className="btn btn-primary fw-bold  btn-lg btn-block"
                  type="button"
                  style={{ fontSize: "15px" }}
                  onClick={() => setShowModal(true)}
                >
                  Confirm and Save
                </button>
              </div>
            </div>
            <div className="col-md-7 order-md-1">
              <h4 className="mb-3">Billing Information</h4>
              <div className="mb-3">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={userDetails.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={userDetails.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label>Address</label>
                <input
                  type="text"
                  className="form-control"
                  value={userDetails.address}
                  onChange={(e) => handleChange("address", e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label>Address 2</label>
                <input
                  type="text"
                  className="form-control"
                  value={userDetails.address2}
                  onChange={(e) => handleChange("address2", e.target.value)}
                />
              </div>
              <div className="row">
                <div className="col-md-5 mb-3">
                  <label>Country</label>
                  <input
                    type="text"
                    className="form-control"
                    value={userDetails.country}
                    onChange={(e) => handleChange("country", e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label>State</label>
                  <input
                    type="text"
                    className="form-control"
                    value={userDetails.state}
                    onChange={(e) => handleChange("state", e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label>Postal Code</label>
                  <input
                    type="text"
                    className="form-control"
                    value={userDetails.zip}
                    onChange={(e) => handleChange("zip", e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label>Car Number</label>
                <input
                  type="text"
                  className="form-control"
                  value={userDetails.carNumber}
                  onChange={(e) => handleChange("carNumber", e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label>Model</label>
                <input
                  type="text"
                  className="form-control"
                  value={userDetails.model}
                  onChange={(e) => handleChange("model", e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label>Reg Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={userDetails.regDate}
                  onChange={(e) => handleChange("regDate", e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label>MOT Exp Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={userDetails.motExpDate}
                  onChange={(e) => handleChange("motExpDate", e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label>Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  value={userDetails.phoneNumber}
                  onChange={(e) => handleChange("phoneNumber", e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Modal for card details */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label>Card Number</label>
            <input
              type="text"
              className="form-control"
              value={cardDetails.cardNumber}
              onChange={(e) => handleCardChange("cardNumber", e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label>Expiry Date</label>
            <input
              type="text"
              className="form-control"
              placeholder="MM/YY"
              value={cardDetails.expiryDate}
              onChange={(e) => handleCardChange("expiryDate", e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label>CVV</label>
            <input
              type="text"
              className="form-control"
              value={cardDetails.cvv}
              onChange={(e) => handleCardChange("cvv", e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePayment}>
            Confirm Payment
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Checkout;
