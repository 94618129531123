
export const TermsCondition = ( ) => {

    return (
        <>
        
        <>
  {/* Please accept our Terms & Conditions */}
  <div className="container bg-light p-4 ">
    <div className="terms">
      <h4>Please accept our Terms &amp; Conditions</h4>
      <p className="mt-4">
        {" "}
        <i className="fa-solid fa-circle-info fs-3" /> Please scroll to the
        bottom of the window to accept Terms and Conditions
      </p>
    </div>
    <div className="definitions mt-3">
      <div className="col-xs-12">
        <div
          id="termsScroll"
          className="scrollable tsandcs-container"
          data-scrollbar="true"
          tabIndex={-1}
          style={{ overflow: "hidden", outline: "none" }}
        >
          <div
            className="scroll-content"
            style={{ transform: "translate3d(0px, 0px, 0px)" }}
          >
            <div className="terms-and-conditions p-4">
              <p className="p-0 mb-0">
                <strong>Definitions</strong>
              </p>
              <p className="p-0 mb-0">
                The following terms have the same meaning throughout this
                document.
              </p>
              
              <p className="p-0 mb-0">
                <strong>Age contribution</strong> means the amount that you need
                to pay towards each replacement component when the vehicle
                exceeds 6 years of age or has covered more than 60,000 miles.
              </p>
              <p className="p-0 mb-0">
                {" "}
                <strong>Application</strong> means any information you have
                given us to support your request to join this plan.
              </p>
              <p className="p-0 mb-0">
                <strong>Breakdown</strong> means the sudden / unexpected
                (unforeseen) failure of a component arising from any permanent
                mechanical, electrical or electronic fault, which causes the
                component to stop working, and means that it needs repairing or
                replacing. Please note that a gradual failure would not be
                covered by this plan.
              </p>
              <p className="p-0 mb-0">
                <strong>Continuous plan</strong> means a monthly plan that will
                renew automatically every month for the minimum period unless
                terminated by us or you.
              </p>
              <p className="p-0 mb-0">
                <strong>Customer schedule</strong> means the schedule which
                confirms the information you gave to us and gives details of
                what is included in the plan. Your personalised warranty
                schedule can be found by logging into your account at
                motoreasy.com
              </p>
              <p className="p-0 mb-0">
                <strong>Fixed period plan</strong> means a bundle of monthly
                plans paid for in advance at a discounted rate that runs for the
                minimum period.
              </p>
              <p className="p-0 mb-0">
                <strong>Gradual failure</strong> is a fault which occurs over
                time, resulting in the eventual failure and deterioration of the
                component.
              </p>
              <p className="p-0 mb-0">
                <strong>Listed component</strong> means any sudden mechanical,
                electrical and electronic component which formed part of the
                vehicle when it was new, and is listed in the schedule of listed
                components included under this plan.
              </p>
              <p className="p-0 mb-0">
                <strong>Minimum period</strong> means the minimum agreement
                period, as stated in your schedule, during which if you cancel,
                you will be charged a cancellation fee and receive a partial
                refund as set out in the cancellation terms below.
              </p>
              <p className="p-0 mb-0">
                {" "}
                <strong>MotorEasy</strong> is your online account through which
                plans are managed and repair requests submitted.
              </p>
              <p className="p-0 mb-0">
                <strong>MotorEasy Network Labour Rate</strong> is the hourly
                labour rate we have negotiated with our independent repair
                network. This is the maximum labour rate we will pay unless a
                higher rate was agreed at inception. NOTE: A MotorEasy Service
                is mandatory when selecting MotorEasy Network.
              </p>
              <p className="p-0 mb-0">
                {" "}
                <strong>MotorEasy Franchised Labour Rate</strong> is higher than
                our independent rate and allows for work to be carried out at
                our franchised dealer network. We will confirm the maximum
                labour rate we will pay in your customer schedule.
              </p>
              <p className="p-0 mb-0">
                <strong>MotorEasy Non-Network Labour Rate</strong> is higher
                than our other labour rates and allows for work to be carried
                out at a garage of your choice. We will confirm the maximum
                labour rate we will pay in your customer schedule.
              </p>
              <p className="p-0 mb-0">
                <strong>Period of the plan</strong> means the length of time
                that this plan applies for, as shown in the customer
                schedule.(The plan will end when the vehicle reaches the maximum
                mileage, shown in the customer schedule, even if this happens
                within the period of the plan.)
              </p>
              <p className="p-0 mb-0">
                <strong>Plan</strong> means this document, the customer schedule
                and any amendments made to it.
              </p>
              <p className="p-0 mb-0">
                <strong>Plan limit</strong> is the maximum amount payable for
                components and labour costs during the period of the plan. This
                is shown in the customer schedule.
              </p>
              <p className="p-0 mb-0">
                <strong>Repair</strong> means the repair of a listed component.
              </p>
              <p className="p-0 mb-0">
                <strong>Repair contribution</strong> means the amount that you
                must pay towards repairs as confirmed in your customer schedule.
              </p>
              <p className="p-0 mb-0">
                <strong>Repair event</strong> means a single repair, or a number
                of repairs that occur at the same time.
              </p>
              <p className="p-0 mb-0">
                <strong>Servicing handbook</strong> means the handbook which the
                manufacturer issued with the vehicle when it was new. This lists
                the servicing and maintenance the manufacturer recommends for
                the vehicle.
              </p>
              <p className="p-0 mb-0">
                <strong>Sudden failure</strong> of a component is its immediate
                and singular failure to function, excluding all other
                contributing factors. This does not include warning lamp
                illumination, a noise or a loss of power as these are symptoms
                and not the proximate cause.
              </p>
              <p className="p-0 mb-0">
                <strong>Sundry items</strong> means coolants, filters, fluids,
                lubricants, oils, refrigerants, and other working materials
                necessary to effect the repair of your vehicle.
              </p>
              <p className="p-0 mb-0">
                <strong>Unexpected(unforeseen) failure</strong>
                is an event which may occur rather than something which will
                occur.
              </p>
              <p className="p-0 mb-0">
                <strong>VAT</strong> means Value Added Tax which will be
                calculated at the prevailing rate.
              </p>
              <p className="p-0 mb-0">
                <strong>Vehicle</strong> means the vehicle mentioned in the
                customer schedule.
              </p>
              <p className="p-0 mb-0">
                <strong>Vehicle rescue operator</strong> means a recognised
                breakdown/rescue operative instructed to attend the vehicle’s
                breakdown. A recognised Vehicle rescue operator could include;
                The AA, RAC, Green Flag, Call Assist or a vehicle rescue
                operator with an affiliation to an FCA Regulated company. The
                vehicle rescue operator must be able to report full details of
                the recovery including, but not limited to, recovery location,
                recovery destination, time and date. The vehicle rescue operator
                must also be VAT registered and be able to provide a VAT invoice
                upon request.
              </p>
              <p className="p-0 mb-0">
                <strong>We, us, our</strong> means Motor Easy Limited whose
                registered address is: Staverton Court, Staverton, Cheltenham,
                Gloucestershire, GL51 0UX and whose registered company number in
                England &amp; Wales is: 08423198.
              </p>
              <p className="p-0 mb-0">
                <strong>Workshop labour time</strong> means the repair time
                allowed by the vehicle manufacturer for their franchised repair
                network, or the published Glass’s ICME repair times for
                independent garages or in our reasonable discretion if no such
                data exists
              </p>
              <p className="p-0 mb-0">
                <strong>You, your, yourself</strong> means the person, or
                company, named in the customer schedule.
              </p>
              
              <p className="p-0 mb-0">
                <strong>What's covered?</strong>
              </p>
              
              <p className="p-0 mb-0">
                All mechanical and electrical components other than those listed
                on p9:
              </p>
              
              <p className="p-0 mb-0">All air conditioning components</p>
              <p className="p-0 mb-0">All braking system components</p>
              <p className="p-0 mb-0">Casings</p>
              <p className="p-0 mb-0">All clutch components</p>
              <p className="p-0 mb-0">All cooling system components</p>
              <p className="p-0 mb-0">
                All electrical components (including electronic components and
                electronic control units)
              </p>
              <p className="p-0 mb-0">All engine components</p>
              <p className="p-0 mb-0">Flywheels (including dual mass flywheels)</p>
              <p className="p-0 mb-0">All fuel system components</p>
              <p className="p-0 mb-0">Infotainment &amp; navigation &amp; telephone systems</p>
              <p className="p-0 mb-0">All gearbox components</p>
              <p className="p-0 mb-0">All ignition system components</p>
              <p className="p-0 mb-0">
                Oils seals and gaskets (that necessitate the removal of the
                engine, gearbox or final drive to replace)
              </p>
              <p className="p-0 mb-0">All steering system components</p>
              <p className="p-0 mb-0">Superchargers</p>
              <p className="p-0 mb-0">All suspension system components</p>
              <p className="p-0 mb-0">All transmission components</p>
              <p className="p-0 mb-0">Turbochargers</p>
              <p className="p-0 mb-0">Wheel hubs and bearings</p>
              <p className="p-0 mb-0">MotorEasy care</p>
              <p className="p-0 mb-0">
                Contact us for a range of motoring care solutions to keep your
                car in perfect running order.
              </p>
              
              <p className="p-0 mb-0">*subject to the list of components that are not covered</p>
              <p className="p-0 mb-0">
                **if a diesel particulate filter needs forced regeneration and
                specialist cleaning we can offer very competitive rates via your
                MotorEasy account.
              </p>
              
              <p className="p-0 mb-0">
                <strong>What's NOT covered?</strong>
              </p>
              
              <p className="p-0 mb-0">
                Mainly bodywork, maintenance and service parts - we've listed
                them below.
              </p>
              
              <p className="p-0 mb-0">Aerials</p>
              <p className="p-0 mb-0">Auxiliary drive belts</p>
              <p className="p-0 mb-0">Batteries</p>
              <p className="p-0 mb-0">Bodywork</p>
              <p className="p-0 mb-0">Brake friction materials</p>
              <p className="p-0 mb-0">Catalytic converters</p>
              <p className="p-0 mb-0">Connectors, wiring, fuses and heating elements</p>
              <p className="p-0 mb-0">Diesel particulate filters</p>
              <p className="p-0 mb-0">Electrical components integral with windows</p>
              <p className="p-0 mb-0">Exhaust pipes</p>
              <p className="p-0 mb-0">Hoses &amp; pipes</p>
              <p className="p-0 mb-0">
                In car entertainment &amp; sat-nav equipment not part of the
                dashboard unit
              </p>
              <p className="p-0 mb-0">Interior &amp; exterior trims</p>
              <p className="p-0 mb-0">Lamps</p>
              <p className="p-0 mb-0">Light bulbs</p>
              <p className="p-0 mb-0">Paintwork</p>
              <p className="p-0 mb-0">Seats</p>
              <p className="p-0 mb-0">Spark plugs</p>
              <p className="p-0 mb-0">Stretched Timing Chains</p>
              <p className="p-0 mb-0">Upholstery</p>
              <p className="p-0 mb-0">Upgraded or revised software &amp; firmware</p>
              <p className="p-0 mb-0">Windows &amp; windscreens</p>
              <p className="p-0 mb-0">Wheels &amp; tyres</p>
              
              <p className="p-0 mb-0">
                There are many reasons a “check engine light” can be
                illuminated, and sometimes a technician may clear the related
                fault code without having repaired the root cause. For this
                reason, we won’t cover repairs relating to fault codes in a
                system’s history log as repairs could have been made previously.
              </p>
              <p className="p-0 mb-0">
                We recommend that your vehicle’s fault code history is reviewed,
                and any necessary repairs carried out, when you apply to join
                MotorEasy.
              </p>
              <p className="p-0 mb-0">
                Important note: Sundries such as coolants, filters, fluids,
                lubricants, oils, refrigerants and other working materials will
                be covered provided the vehicle is not within 1,000 miles of its
                next due service and that replacement is necessitated by a
                covered repair.
              </p>
              
              <p className="p-0 mb-0">
                <strong>What IS included in your MotorEasy plan?</strong>
              </p>
              
              <p className="p-0 mb-0">
                Following breakdown of listed components, or damage to listed
                components caused by the breakdown of any other listed component
                or due to consequential damage caused by any non-listed
                component we will pay, at our absolute discretion, towards the
                cost of diagnosing the fault and repairing the vehicle up to the
                plan limit.
              </p>
              <p className="p-0 mb-0">
                This breakdown must happen during the period of the plan. The
                breakdown may have, but is not limited to:
              </p>
              <ul>
                <li>Occurred at the roadside</li>
                <li>Been noticed during a service</li>
                <li>Caused an MOT failure</li>
                <li>Overheated the car's engine</li>
              </ul>
              <p className="p-0 mb-0">
                All of the above situations fall within the scope of the
                MotorEasy plan.
              </p>
              <p className="p-0 mb-0">
                Your vehicle is protected against unexpected and sudden failures
                to listed components from the date of inception.
              </p>
              <p className="p-0 mb-0">
                This is where a mechanical or electrical Part suddenly and
                unexpectedly stops working and requires immediate repair or
                replacement before it will work again.
              </p>
              <p className="p-0 mb-0">
                You may have to pay towards improving the condition of the
                vehicle, depending on age or mileage at the time of breakdown
                (see section “general conditions” Improving the condition of the
                vehicle (condition 5)). You may also have to pay if the labour
                rate the repairer charges is higher than the network labour
                rate.
              </p>
              
              <p className="p-0 mb-0">
                <strong>Other benefits</strong>
              </p>
              <p className="p-0 mb-0">The plan includes the following benefits:</p>
              
              <p className="p-0 mb-0">
                <strong>Vehicle collection</strong>
              </p>
              <p className="p-0 mb-0">
                If your vehicle is able to be driven safely we can arrange for
                our repair network to send a driver to collect it for repair. If
                repairs are covered and paid for under the plan, or you have
                repair work carried out by our network at your expense, there
                will be no charge for this service.
              </p>
              <p className="p-0 mb-0">Otherwise, a £20 charge may be made for this service.</p>
              
              <p className="p-0 mb-0">
                <strong>Vehicle recovery</strong>
              </p>
              <p className="p-0 mb-0">
                We will pay for the cost of recovering the vehicle to the
                nearest garage, for covered repairs, up to £150 including VAT.
              </p>
              <p className="p-0 mb-0">
                If you don't have breakdown cover then you can call the
                MotorEasy Breakdown 24 hour pay on use roadside assistance line
                for immediate help on 01206 785949. All costs involved will be
                your responsibility and payment will be required by credit/debit
                card before help is provided. We may be able to reimburse you
                some, or all of these costs, if the cause of the breakdown is
                covered by your MotorEasy warranty.
              </p>
              
              <p className="p-0 mb-0">
                <strong>Overseas use</strong>
              </p>
              <p className="p-0 mb-0">
                This plan includes repairs undertaken in Great Britain and
                Northern Ireland. If you use your vehicle overseas for less than
                60 days per year this protection is extended to repairs carried
                out overseas. You will have to pay the repairer direct and
                request reimbursement from us. We will reimburse you, in our
                discretion, within our normal limits for a validated repair.
                Refunds will exclude any non UK VAT.
              </p>
              
              <p className="p-0 mb-0">
                <strong>What IS NOT included in your MotorEasy plan?</strong>
              </p>
              <p className="p-0 mb-0">
                We will not pay for repairing or replacing components not
                specifically listed in the warranty schedule. Also, we will not
                pay for costs caused by, arising from, or connected with, the
                following:
              </p>
              <ol>
                <li>
                  Your component contributions or repair contributions that
                  apply to this plan.
                </li>
                <li>
                  {" "}
                  Vehicles that are owned or kept by, but not limited to, the
                  motor trade or vehicles that have been used for: competitive
                  purposes including rallying, racing, time trial and
                  pacemaking; hire or reward, as a taxi, by a driving school or
                  for commercial delivery purposes such as despatch or delivery
                  courier; or off-road use.
                </li>
                <li>
                  Any loss or damage caused by a traffic accident, accidental
                  damage, theft or attempted theft, the vehicle not being used
                  in accordance with the manufacturer’s recommendations, or any
                  act which is wilful, against the law or negligent.
                </li>
                <li>
                  The repair or replacement of any part that simply has a
                  ‘recommendation’ to be replaced alongside the failed, covered
                  part. Only failed parts are covered under this Plan.
                </li>
                <li>
                  Any loss arising from the vehicle not having been serviced in
                  line with the conditions of this plan either during the term
                  of the plan or (in the opinion of a suitably qualified
                  independent engineer appointed by us, but NOT employed by us)
                  prior to the start of the plan and whether or not the vehicle
                  was owned by you at the time; you not maintaining the vehicle
                  properly as set out in your servicing handbook; through
                  failure to use the manufacturer’s recommended type, grade and
                  quality of engine oil; or where the camshaft drive belt has
                  not been changed in accordance with the manufacturer’s
                  recommendations.
                </li>
                <li>
                  {" "}
                  Any loss where the odometer has been tampered with, altered or
                  disconnected, affecting the recorded mileage.
                </li>
                <li>
                  Repairing or replacing components which have not suffered a
                  breakdown, including repairs to rectify high oil consumption
                  or poor fuel economy where there is no breakdown of a listed
                  component, or the cost of any work or vehicle storage not
                  associated with a valid repair.
                </li>
                <li>
                  Any repair, replacement, loss, damage or liability which
                  should be paid or otherwise settled by another company,
                  finance agreement, warranty, guarantee, goodwill settlement or
                  repair; any fault causing a manufacturer recall; any
                  manufacturer modifications; any fault that can be rejected to
                  the seller under the Consumer Rights Act or any fault which
                  had previously caused a fault or failure code to be logged in
                  any computerised system’s history where the system was reset
                  without any repairs being made.
                </li>
                <li>
                  We will not pay for repairing or replacing components which
                  have not suffered a breakdown; for rectifying oil leaks where
                  no oil is dripping from a joint or seal; when repairs to
                  rectify the oil leak do not necessitate the removal of the
                  engine, transmission or final drive unit. Also, we will not
                  pay for any breakdown caused by components which were faulty
                  before this plan started as identified in a garage visit prior
                  to claiming. You can still contact us to arrange these
                  repairs, saving by using our network.
                </li>
                <li>
                  Breakdown or damage to components (whether listed or not)
                  caused by frost, water, freezing liquids, worn friction
                  materials (including a worn clutch friction plate damaging a
                  flywheel), carbon build up, corrosion, oxidisation, blockages,
                  contamination, sludge or silt, or other waste matter that has
                  prevented the components from working properly.
                </li>
                <li>
                  Breakdown (including repeat repairs) or damage caused by poor
                  workmanship or faulty components, based on information
                  provided by a suitably qualified independent engineer
                  appointed by us, or if the breakdown is as a result of a
                  gradual failure.
                </li>
                <li>
                  Repairing or replacing components identified at any time,
                  including but not limited to at the time of claim, as being
                  faulty before this plan started
                </li>
                <li>
                  Service, maintenance and MOT repair related components
                  including (but not limited to) anti-freeze, brake drums, brake
                  discs, brake fluid &amp; brake friction materials (brake pads
                  &amp; brake shoes), exhaust pipes &amp; exhaust pipe gaskets,
                  filters, grease, oils, sparking plugs, stretched timing
                  chains, refrigerants and tyres unless you have made additional
                  purchases for service work and MOT repairs (in which case you
                  will be subject to the separate terms of these purchases).
                </li>
                <li>
                  Burnt or worn-out clutch components, the build-up of carbon
                  deposits (including burnt or carbonised valves and removing
                  carbon deposits), modifications to components of the vehicle,
                  electronic system firmware and software updates (howsoever
                  necessary), or cost arising from manufacturer recalls.
                </li>
                <li>
                  Vehicles which are owned, temporarily or permanently, by a
                  business or employee of a business set up to sell, service or
                  repair motor vehicles.
                </li>
                <li>
                  Failure of listed components which a qualified engineer,
                  appointed by us, believes has been aggravated due to the
                  vehicle being driven-on after the fault was more than likely
                  to have been apparent to the driver, or due to the vehicle
                  being driven in an unsympathetic manner (for example
                  repeatedly running the engine at full power before the oil has
                  fully warmed up or repeatedly not allowing the engine to reach
                  operating temperature before turning it off). In the case of a
                  vehicle being driven on we will only be liable for the
                  reasonable repair costs the engineer believes would have
                  resulted if the vehicle had been stopped at the earliest
                  opportunity.
                </li>
                <li>
                  Any repair costs not agreed with us prior to the work being
                  completed or any losses not directly met by the terms and
                  conditions of this plan (note that any discretion applied to a
                  previous repair, on a goodwill basis, will not set precedent).
                </li>
                <li>
                  Any liability for death, bodily injury, damage to other
                  property, loss of earnings, out of pocket expenses or any
                  other loss caused directly or indirectly by a repair event;
                  any liability caused directly or indirectly by war, riot, or
                  any similar event; by vandalism, theft or attempted theft from
                  the vehicle; or by bad weather such as lightning, wind or
                  flood.
                </li>
              </ol>
              <p className="p-0 mb-0">
                <strong>How to request repairs</strong>
              </p>
              <p className="p-0 mb-0">
                Prevent further damage – do not continue to drive if your
                vehicle is faulty.
              </p>
              <p className="p-0 mb-0">
                You should do all you reasonably can to protect your vehicle
                from further damage.
              </p>
              <p className="p-0 mb-0">
                We will not pay for repairs that are necessary because you have
                not looked after your vehicle properly.
              </p>
              
              <p className="p-0 mb-0">
                <strong>Repair by the roadside</strong>
              </p>
              <p className="p-0 mb-0">
                You should pay the vehicle rescue operator for repairs and
                obtain documented proof of the reason for breakdown and a
                receipted repair invoice. You should then check that the failed
                component is listed under the plan and then refer to the payment
                of repairs section below.
              </p>
              
              <p className="p-0 mb-0">
                <strong>Repair by the garage</strong>
              </p>
              <ol>
                <li>
                  <strong>Login to your MotorEasy account</strong>
                </li>
                <p className="p-0 mb-0">
                  You must report a fault to us as soon as practicable and in
                  any case within a maximum of 7 days by going to your MotorEasy
                  account and contacting us via the ‘Report a claim’ process.
                  Note: a failure to report the fault within 7 days may lead to
                  your claim being declined.
                </p>
                <p className="p-0 mb-0">
                  NO GARAGE REPAIRS ARE TO BE COMMENCED UNTIL THE COST HAS BEEN
                  AGREED BY US AND ANY GARAGE REPAIRS CARRIED OUT WITHOUT THE
                  PRIOR AGREEMENT OF COSTS WILL NOT BE PAID.
                </p>
                <p className="p-0 mb-0">
                  We will contact you after we have reviewed your initial
                  request and we will tell you where the nearest network garage
                  is. You can us a different garage if you prefer and you have
                  selected and paid for a higher labour rate to apply to your
                  plan (this will be confirmed in your customer schedule), but
                  if you use a network garage, the repair process will be
                  simpler, easier, quicker and speedier.
                </p>
                <p className="p-0 mb-0">The benefits of using our network garages are:</p>
                <ul>
                  <li>
                    Repairs can be directed to workshops specialising in the
                    particular fault that has occurred
                  </li>
                  <li>
                    The repair process can be fully monitored and controlled
                  </li>
                  <li>
                    Central billing will mean that you don’t have to pay and
                    re-claim – we pay the garage directly
                  </li>
                  <li>
                    We can offer you discounts on other work you may want
                    carried out on your car
                  </li>
                </ul>
                <li>
                  <strong>Documents required</strong>
                </li>
                <p className="p-0 mb-0">
                  If you request a repair, you will need to provide proof that
                  you have had the vehicle serviced regularly and your latest
                  MOT certificate. If you use one of our network garages, they
                  will be able to check these for you.
                </p>
                <p className="p-0 mb-0">
                  If you prefer, and you have selected and paid for a higher
                  labour rate to apply to your plan, you can take your vehicle
                  to any other VAT-registered garage.
                </p>
                <p className="p-0 mb-0">You must take with you the following:</p>
                <ul>
                  <li>Your plan reference number</li>
                  <li>
                    Proof that you have had the vehicle serviced regularly (last
                    service invoice) and your latest MOT certificate (we may
                    also ask for these documents to be photographed and uploaded
                    to your MotorEasy account).
                  </li>
                </ul>
                <li>
                  <strong>The garage</strong>
                </li>
                <p className="p-0 mb-0">
                  The garage will determine the cause of the breakdown and check
                  that this plan includes the components directly causing the
                  breakdown.
                </p>
                <p className="p-0 mb-0">
                  The garage will be responsible for telephoning us on 0800 131
                  0001 to agree the cost of repair, or by putting a job request
                  through to MotorEasy using our online garage platform. The
                  garage must include in their request a description, and the
                  exact content of, any relevant fault or failure codes read
                  from the vehicle’s computerised systems.
                </p>
                <p className="p-0 mb-0">
                  We have the right to examine the vehicle and to have the
                  breakdown assessed by an independent expert before the garage
                  can start any repairs. We can alternatively take your vehicle
                  to one of our approved garages to undertake repair work if we
                  feel this is appropriate.
                </p>
                <p className="p-0 mb-0">
                  The garage must not start any repairs until we have authorised
                  the repair and agreed the cost.
                </p>
                <p className="p-0 mb-0">
                  Should you decide to give permission to the repairer to
                  commence work on your vehicle without authorisation from us
                  you do so in the knowledge that we reserve the right not to
                  pay for repairs, due to the fact that you have denied us the
                  opportunity to inspect your vehicle to determine the cause of
                  failure.
                </p>
                <li>
                  <strong>After the repair</strong>
                </li>
                <p className="p-0 mb-0">
                  Once the repairs have been completed, we will pay the garage
                  if it is one of our network garages. If you use a different
                  garage, you must pay for the repair and claim the cost back
                  from us.
                </p>
                <p className="p-0 mb-0">
                  <strong>
                    NOTE: GARAGE REPAIR INVOICES MUST BE MADE OUT TO MOTOREASY
                    AND SHOW OUR ADDRESS. IF THIS IS NOT DONE THEN WE WILL NOT
                    BE ABLE TO REIMBURSE THE COST OF ANY VAT ELEMENT.
                  </strong>
                </p>
              </ol>
              <p className="p-0 mb-0">
                <strong>Payments</strong>
              </p>
              <ol>
                <li>
                  You may pay for a fixed period plan in full by one single
                  payment at the start of the plan or by instalments. A
                  continuous plan runs for one month and you must pay every
                  month for the coming month. The customer schedule will confirm
                  which type of plan you have and
                </li>
              </ol>
            </div>
            {/* content 5 end */}
          </div>
        </div>
      </div>
    </div>
  </div>
</>

        </>
    )
}