import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "./SearchFilter.css";

const SearchComponent = () => {
  const [makers, setMakers] = useState([]);
  const [selectedCar, setSelectedCar] = useState("");
  const [selectedMaker, setSelectedMaker] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [models, setModels] = useState([]);
  const [cars, setCars] = useState([]);
  const navigate = useNavigate();

  // Fetch all cars on component mount
  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await axios.get(
          "https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars"
        );
        if (response.data && Array.isArray(response.data.cars)) {
          setCars(response.data.cars);
        } else {
          console.error("Unexpected response data format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching car data:", error);
      }
    };

    fetchCars();
  }, []);

  // Fetch makers based on selected car
  useEffect(() => {
    const fetchMakers = async () => {
      if (!selectedCar) return;
      try {
        const response = await axios.get(
          `https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-maker?car_id=${selectedCar}`
        );
        if (response.data && Array.isArray(response.data.maker)) {
          setMakers(response.data.maker);
        } else {
          console.error("Unexpected response data format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching makers:", error);
      }
    };

    fetchMakers();
  }, [selectedCar]);

  // Fetch models based on selected car and maker
  useEffect(() => {
    const fetchModels = async () => {
      if (!selectedCar || !selectedMaker) return;
      try {
        const response = await axios.get(
          "https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-models",
          {
            params: {
              car_id: selectedCar,
              maker_id: selectedMaker,
            },
          }
        );
        if (response.data && Array.isArray(response.data.models)) {
          setModels(response.data.models);
        } else {
          console.error("Unexpected response data format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, [selectedCar, selectedMaker]);

  // Fetch car details and navigate to product details page
  const fetchCarDetails = async () => {
    if (!selectedCar || !selectedMaker) {
      toast.error("Selected car ID is empty or invalid.");
      return;
    }

    try {
      const response = await axios.post(
        "https://phplaravel-1324103-4877027.cloudwaysapps.com/api/get-car-img",
        {
          car_id: selectedCar,
          maker_id: selectedMaker,
          model_id: selectedYear,

        }
      );

      if (response.data && Array.isArray(response.data.all_images)) {
        const carImages = response.data.all_images.map((img) => ({
          ...img,
          url: `${response.data.imagepath}${img.car_image}`,
        }));

        navigate("/product-details", {
          state: {
            carImages,
            selectedCarName: response.data.car_name,
            selectedModelName: response.data.model_name,
            car_id: selectedCar,
            maker_id: selectedMaker,
            model_id: selectedYear,
          },
        });
        console.log("selected model id id", selectedYear);
      } else {
        console.error("Unexpected response data format:", response.data);
        navigate("/product-details", {
          state: { error: "An error occurred while fetching the car images." },
        });
      }
    } catch (error) {
      console.error("Error fetching car images:", error);
      navigate("/product-details", {
        state: { error: "An error occurred while fetching the car images." },
      });
    }
  };

  const handleCarModelChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedYear(selectedValue);

   
  };

  return (
    <div className="container set_max_width_banner">
      <Container fluid className="set_max_width_banner search-container">
        <Row className="text-center banner">
          <Col>
            <h2 className="fw-bold text-light">Find a Service for your Vehicle</h2>
          </Col>
        </Row>
        <Row className="justify-content-center mt-0">
          <Col xs={12} className="search-box-container pb-3">
            <Form className="search-box py-5 px-2" style={{ width: "100%" }}>
              <Row className="w-100">
                <Col xs={12} md={6} className="search-box-input-container">
                  <Form.Group className="search-item w-100 mb-2 d-flex align-items-center">
                    <Form.Label htmlFor="make">Make</Form.Label>
                    <Form.Control
                      as="select"
                      id="make"
                      value={selectedCar}
                      onChange={(e) => setSelectedCar(e.target.value)}
                    >
                      <option value="">-make-</option>
                      {cars.map((car) => (
                        <option key={car.id} value={car.id}>
                          {car.car_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="search-item w-100 mb-2">
                    <Form.Label htmlFor="model">Model</Form.Label>
                    <Form.Control
                      as="select"
                      id="model"
                      value={selectedMaker}
                      onChange={(e) => setSelectedMaker(e.target.value)}
                    >
                      <option value="">-model-</option>
                      {makers.map((maker) => (
                        <option key={maker.id} value={maker.id}>
                          {maker.maker_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="search-item w-100 mb-2">
                    <Form.Label htmlFor="year">Year Model</Form.Label>
                    <Form.Control
                      as="select"
                      id="year"
                      value={selectedYear}
                      onChange={handleCarModelChange}
                    >
                      <option value="">-year-</option>
                      {models.map((model, index) => (
                        <option key={index} value={model.id}>
                          {model.model_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="search-item w-100 mb-2">
                    <Form.Label htmlFor="year">Current Km</Form.Label>
                    <Form.Control as="select" id="km">
                      <option value="">-km-</option>
                      <option value="">2000 km</option>
                      <option value="">3000 km</option>
                      <option value="">4000 km</option>
                      <option value="">5000 km</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} className="search-box-input-container d-flex flex-column">
                  <Form.Group className="search-item w-100 mb-1 align-items-start">
                    <Form.Label htmlFor="year" className="mt-2">Engine Type</Form.Label>
                    <div className="multiple-sele">
                      <Form.Control as="select" id="km" className="w-100">
                        <option value="">-engine-</option>
                        <option value="">Diesel</option>
                        <option value="">Petrol</option>
                      </Form.Control>
                      <Form.Control as="select" id="km" className="w-100">
                        <option value="">-select (petrol, diesel, Hybrid, EV)-</option>
                        <option value="">2000 km</option>
                        <option value="">3000 km</option>
                        <option value="">4000 km</option>
                        <option value="">5000 km</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                  <Button
                    className="search-button w-50 align-self-end"
                    onClick={fetchCarDetails}
                  >
                    SEARCH
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row className="categories flex-wrap justify-content-center">
          <Col xs={12} sm={12} md={4} lg={3} className="category text-center text-dark mb-3">
            <Link to="/Used-Car-Warrenty">Used Car Warranty</Link>
          </Col>
          <Col xs={12} sm={12} md={4} lg={3} className="category text-center mb-3">
            <Link to="/Vehicle-inspections">Vehicle Inspection</Link>
          </Col>
          <Col xs={12} sm={12} md={4} lg={3} className="category text-center mb-3">
            <Link to="/Vehicle-service">Vehicle Service</Link>
          </Col>
          <Col xs={12} sm={12} md={4} lg={3} className="category text-center mb-3">
            <Link to="/Road-side-assistance">Roadside Assistance</Link>
          </Col>
          <Col xs={12} sm={12} md={4} lg={3} className="category text-center mb-3">
            <Link to="/Car-Sell">Sell Your Car</Link>
          </Col>
          <Col xs={12} sm={12} md={4} lg={3} className="category text-center mb-3">
            <Link to="/Car-buy">Buy A Car</Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SearchComponent;
