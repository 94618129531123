import React, { useState, useEffect } from 'react';
import { Table, Container } from 'react-bootstrap';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Ensure this is your Firestore instance

const RecentOrders = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "Orders"));
                const fetchedOrders = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        date: data.date,
                        name: data.userDetails.name,
                        shipTo: `${data.userDetails.state}, ${data.userDetails.country}`, // Adjust based on available fields
                        carNumber: data.userDetails.carNumber,
                        model: data.userDetails.model,
                        saleAmount: `$${data.totalPrice}`,
                        discount: `$${data.discount}`,
                        pendingAmount: `$${data.totalPrice - data.discount}`, // Calculate pending amount
                        status: data.status || "Pending" // Default to "Pending" if status is not available
                    };
                });
                setOrders(fetchedOrders);
            } catch (error) {
                console.error("Error fetching orders: ", error);
            }
        };

        fetchOrders();
    }, []);

    return (
        <Container>
            <h4 className="mb-3">Recent Orders</h4>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Ship To</th>
                        <th>Car Number</th>
                        <th>Model</th>
                        <th>Sale Amount</th>
                        <th>Discount</th>
                        <th>Pending Amount</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.length > 0 ? (
                        orders.map((order, index) => (
                            <tr key={index}>
                                <td>{order.date}</td>
                                <td>{order.name}</td>
                                <td>{order.shipTo}</td>
                                <td>{order.carNumber}</td>
                                <td>{order.model}</td>
                                <td>{order.saleAmount}</td>
                                <td>{order.discount}</td>
                                <td>{order.pendingAmount}</td>
                                <td>
                                    <span className={`badge ${getStatusBadgeClass(order.status)}`}>
                                        {order.status}
                                    </span>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="9">No orders found.</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <a href="#">See more orders</a>
        </Container>
    );
};

// Helper function to get the badge class based on the status
const getStatusBadgeClass = (status) => {
    switch (status) {
        case 'Delivered':
            return 'bg-success';
        case 'Pending':
            return 'bg-warning';
        case 'Cancelled':
            return 'bg-danger';
        default:
            return 'bg-secondary';
    }
};

export default RecentOrders;
