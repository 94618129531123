import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const AdminHeader = () => {
  const { currentUser, signOut } = useAuth();
  const [loggingOut, setLoggingOut] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    setLoggingOut(true);
    try {
      await signOut();
      toast.success("You are now logged out");
      setTimeout(() => {
        navigate("/Sign_In");
      }, 1000);
    } catch (error) {
      console.error("Failed to log out", error);
      toast.error("Failed to log out. Please try again later.");
    } finally {
      setLoggingOut(false);
    }
  };

  const handleNavbarToggle = () => {
    const navbarCollapse = document.getElementById("navbarSupportedContent");
    if (navbarCollapse.classList.contains("show")) {
      navbarCollapse.classList.remove("show");
    } else {
      navbarCollapse.classList.add("show");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="second_header sticky-top top-0 w-100 rounded-0 admin_header">
        <header>
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <div className="row w-100 d-flex align-items-center justify-content-between">
                <div className="col-sm-7">
                  <h2 className="mb-0">Admin Dashboard</h2>
                </div>
                <div className="log-sign col-md-5 text-end">
                {currentUser ? (
                  <div className="dropdown">
                    <button
                      className="btn btn-dark user-btn"
                      style={{ backgroundColor: "#003a63" }}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {currentUser.email.split("@")[0]}{" "}
                      <i className="fa fa-user ms-2 bg-dark p-2 rounded-circle"></i>
                    </button>
                    <ul className="dropdown-menu user-dropdown-menu">
                      <li className="dropdown-link">
                        <Link className="dropdown-item" onClick={handleLogout}>
                          {loggingOut ? "Logging out..." : "Log out"}
                        </Link>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <>
                    <Link to="/Sign_in">
                      <button className="btn-transparent">Log in</button>
                    </Link>
                  </>
                )}
              </div>
              </div>
             
            </div>
          </nav>
        </header>
      </div>
    </>
  );
};

export default AdminHeader;
