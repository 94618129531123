import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { Form, Button, Container, Row, Col, Table } from "react-bootstrap";
import AdminHeader from "../../../Components/AdminHeader";
import Footer from "../../../Components/Footer";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import axios from "axios";
import Sidebar from "../../../Components/Sidebar";

const AdminPricings = () => {
  const services = [
    "Used Car warranty",
    "Vehicle inspection",
    "Vehicle Service",
    "Road Side",
    "Battery Replacement",
    "Windscreen Repair",
    "Home",
  ];

  const plans = [
    { name: "PlanA", duration: "12 months", price: 289 },
    { name: "PlanB", duration: "24 months", price: 249 },
    { name: "PlanC", duration: "36 months", price: 229 },
  ];

  const [selectedService, setSelectedService] = useState(services[0]);
  const [pricingData, setPricingData] = useState({
    PlanA: { duration: plans[0].duration, price: plans[0].price },
    PlanB: { duration: plans[1].duration, price: plans[1].price },
    PlanC: { duration: plans[2].duration, price: plans[2].price },
  });
  const [pricings, setPricings] = useState([]);
  const [editing, setEditing] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [cars, setCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState("");
  const [carModel, setCarModel] = useState([]);
  const [selectedCarModel, setSelectedCarModel] = useState("");
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const carsData = await axios.get(
          "https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars"
        );
        setCars(carsData.data.cars);
      } catch (error) {
        console.error("Failed to fetch cars", error.message);
      }
    };

    fetchCars();
  }, []);

  useEffect(() => {
    if (selectedCar) {
      const fetchCarModels = async () => {
        try {
          const response = await axios.get(
            `https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-maker?car_id=${selectedCar}`
          );
          setCarModel(response.data.maker);
        } catch (error) {
          console.error("Error fetching car models:", error);
        }
      };

      fetchCarModels();
    } else {
      setCarModel([]);
    }
  }, [selectedCar]);

  useEffect(() => {
    fetchPricings();
  }, []);

  const fetchPricings = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Pricings_Admin"));
      const pricingList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPricings(pricingList);
    } catch (error) {
      console.error("Error fetching pricing data:", error);
      toast.error("Failed to fetch pricing data.");
    }
  };

  const handleServiceChange = (e) => {
    setSelectedService(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const carName = selectedCar.car_name || "";
    
    const carModelName =
      carModel.find((model) => model.id === selectedCarModel)?.maker_name || "";

    if (!selectedCar || !selectedCarModel) {
      toast.error("Please select both car and car model.");
      return;
    }

    const pricingDetails = {
      service: selectedService,
      pricing: pricingData,
      carName,
      carId: selectedCar,
      carModel: carModelName,
      carModelId: selectedCarModel,
      currency: "AU$",
    };

    try {
      setLoading(true);
      if (editing) {
        await updateDoc(doc(db, "Pricings_Admin", editing.id), pricingDetails);
        toast.success("Pricing data updated successfully!");
      } else {
        await addDoc(collection(db, "Pricings_Admin"), pricingDetails);
        toast.success("Pricing data saved successfully!");
      }

      setPricingData({
        PlanA: { duration: plans[0].duration, price: plans[0].price },
        PlanB: { duration: plans[1].duration, price: plans[1].price },
        PlanC: { duration: plans[2].duration, price: plans[2].price },
      });
      setSelectedService(services[0]);
      setSelectedCar("");
      setSelectedCarModel("");
      setEditing(null);
      fetchPricings();
    } catch (error) {
      console.error("Error saving pricing data:", error);
      toast.error("Failed to save pricing data.");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (pricing) => {
    setSelectedService(pricing.service);
    setPricingData(pricing.pricing);
    setSelectedCar(pricing.carId);
    setSelectedCarModel(pricing.carModelId);
    setEditing(pricing);
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "Pricings_Admin", id));
      toast.success("Pricing data deleted successfully!");
      fetchPricings();
    } catch (error) {
      console.error("Error deleting pricing data:", error);
      toast.error("Failed to delete pricing data.");
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const displayPricings = pricings.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <>
      <AdminHeader />
      <div className="container-fluid p-0">
        <div className="row">
          <Sidebar/>
          <div className="col-md-9">
          <Container fluid="md">
        <h1 className="my-4">Add or Update Pricing Plans</h1>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Select Service
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                value={selectedService}
                onChange={handleServiceChange}
              >
                {services.map((service) => (
                  <option key={service} value={service}>
                    {service}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="carSelect">
            <Form.Label column sm={2}>
              Select Car
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                value={selectedCar}
                onChange={(e) => setSelectedCar(e.target.value)}
              >
                <option value="">Select Car</option>
                {cars.map((car) => (
                  <option key={car.id} value={car.id}>
                    {car.car_name}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="modelSelect">
            <Form.Label column sm={2}>
              Select Car Model
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                value={selectedCarModel}
                onChange={(e) => setSelectedCarModel(e.target.value)}
              >
                <option value="">Select Model</option>
                {carModel.map((model) => (
                  <option key={model.id} value={model.id}>
                    {model.maker_name}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>

          {plans.map((plan) => (
            <Form.Group as={Row} className="mb-3" key={plan.name}>
              <Form.Label column sm={2}>
                {plan.name}
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  type="text"
                  placeholder="Duration"
                  value={pricingData[plan.name]?.duration || ""}
                  onChange={(e) =>
                    setPricingData((prevState) => ({
                      ...prevState,
                      [plan.name]: {
                        ...prevState[plan.name],
                        duration: e.target.value,
                      },
                    }))
                  }
                />
              </Col>
              <Col sm={5}>
                <Form.Control
                  type="number"
                  placeholder="Price"
                  value={pricingData[plan.name]?.price || ""}
                  onChange={(e) =>
                    setPricingData((prevState) => ({
                      ...prevState,
                      [plan.name]: {
                        ...prevState[plan.name],
                        price: parseFloat(e.target.value) || 0,
                      },
                    }))
                  }
                />
              </Col>
            </Form.Group>
          ))}

          <Button variant="primary" type="submit" disabled={loading}>
            {editing ? "Update Pricing" : "Add Pricing"}
          </Button>
        </Form>
        <hr />
        <h2>Pricings</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Service</th>
              <th>Car</th>
              <th>Car Model</th>
              <th>PlanA</th>
              <th>PlanB</th>
              <th>PlanC</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {displayPricings.map((pricing) => (
              <tr key={pricing.id}>
                <td>{pricing.service}</td>
                <td>{pricing.carName}</td>
                <td>{pricing.carModel}</td>
                <td>
                  {pricing.pricing.PlanA.duration} - {pricing.pricing.PlanA.price}
                </td>
                <td>
                  {pricing.pricing.PlanB.duration} - {pricing.pricing.PlanB.price}
                </td>
                <td>
                  {pricing.pricing.PlanC.duration} - {pricing.pricing.PlanC.price}
                </td>
                <td>
                  <Button variant="warning"
                  className="p-2 " onClick={() => handleEdit(pricing)}>
                  <i className="fa fa-edit"></i>
                  </Button>{" "}
                  <Button
                  className="p-2 "
                    variant="danger"
                    onClick={() => handleDelete(pricing.id)}
                  >
                  <i className="fa fa-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Math.ceil(pricings.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPricings;
