  import React, { useEffect, useState } from 'react';
  import { Form, Button, Table, Container, Modal } from 'react-bootstrap';
  import axios from 'axios';
  import { toast } from 'react-toastify';

  const CarModelManager = () => {
    const [cars, setCars] = useState([]);
    const [makers, setMakers] = useState([]);
    const [selectedCar, setSelectedCar] = useState('');
    const [selectedMaker, setSelectedMaker] = useState('');
    const [year, setYear] = useState('');
    const [carType, setCarType] = useState('');
    const [engineCapacity, setEngineCapacity] = useState('');
    const [serviceType, setServiceType] = useState('');
    const [productDesc, setProductDesc] = useState(''); // New state
    const [productCode, setProductCode] = useState(''); // New state
    const [headTitle, setHeadTitle] = useState(''); // New state
    const [models, setModels] = useState([]);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [updateModelId, setUpdateModelId] = useState(null);
    const [newYear, setNewYear] = useState('');
    const [newCarType, setNewCarType] = useState('');
    const [newEngineCapacity, setNewEngineCapacity] = useState('');
    const [newServiceType, setNewServiceType] = useState('');
    const [newproductDesc,setNewProductDesc]= useState('');
    const [newproductCode,setNewProductCode]= useState('');
    const [newHeadTitle, setNewHeadTitle]= useState('');
    const [loading, setLoading] = useState(false);

    // Fetch all cars
    useEffect(() => {
      const fetchCars = async () => {
        
        try {
          const response = await axios.get('https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars');
          setCars(response.data.cars);
        } catch (error) {
          console.error('Error fetching cars:', error);
        }
      };

      fetchCars();
    }, []);

    // Fetch makers based on selected car
    useEffect(() => {
      const fetchMakers = async () => {
        if (!selectedCar) return; // Avoid fetching if no car is selected
        try {
          const response = await axios.get(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-maker?car_id=${selectedCar}`);
          setMakers(response.data.maker);
        } catch (error) {
          console.error('Error fetching makers:', error);
        }
      };

      fetchMakers();
    }, [selectedCar]);

    // Fetch models based on selected car and maker
    const fetchModels = async () => {
      if (!selectedCar || !selectedMaker) return; // Avoid fetching if car or maker is not selected
      try {
        const response = await axios.get('https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-models', {
          params: {
            car_id: selectedCar,
            maker_id: selectedMaker
          }
        });
        setModels(response.data.models);
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    };

    useEffect(() => {
      fetchModels(); // Call fetchModels when selectedCar or selectedMaker changes
    }, [selectedCar, selectedMaker]);

    // Add new model
    const handleAddModel = async () => {
      if (!selectedCar || !selectedMaker || !year || !carType || !engineCapacity || !serviceType || !productDesc || !productCode || !headTitle) {
        toast.error('Please fill all fields.');
        return;
      }
      try {
        setLoading(true);
        await axios.post('https://phplaravel-1324103-4877027.cloudwaysapps.com/api/store-cars-model', {
          car_id: selectedCar,
          maker_id: selectedMaker,
          model_name: year,
          car_type: carType,
          engine_capacity: engineCapacity,
          service_type: serviceType,
          product_desc: productDesc, // Include new field
          product_code: productCode, // Include new field
          head_title: headTitle // Include new field
        });
        toast.success('Model added successfully!');
        fetchModels(); // Refresh models list
      } catch (error) {
        toast.error('Error adding model.');
      } finally {
        setLoading(false);
      }
    };

    // Open update modal
    const handleOpenUpdateModal = (model) => {
      setUpdateModelId(model.id);
      setNewYear(model.model_name);
      setNewCarType(model.car_type);
      setNewEngineCapacity(model.engine_capacity);
      setNewServiceType(model.service_type);
      setNewProductDesc(model.product_desc);
      setNewProductCode(model.product_code);
      setNewHeadTitle(model.head_title)
      setShowUpdateModal(true);
    };

    // Update model
    const handleUpdateModel = async () => {
      if (!newYear || !newCarType || !newEngineCapacity || !newServiceType) {
        toast.error('Please fill all fields.');
        return;
      }
      setLoading(true);
      try {
        await axios.put(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/update-cars-model/${updateModelId}`, {
          car_id: selectedCar,
          maker_id: selectedMaker,
          model_name: newYear,
          car_type: newCarType,
          engine_capacity: newEngineCapacity,
          service_type: newServiceType,
          product_desc: newproductDesc,
          product_code: newproductCode,
          head_title: newHeadTitle
        });
        toast.success('Model updated successfully!');
        setShowUpdateModal(false);
        fetchModels(); // Refresh models list
      } catch (error) {
        toast.error('Error updating model.');
      }
      setLoading(false);
    };

    // Delete model
    const handleDeleteModel = async (modelId) => {
      try {
        await axios.delete(`https://phplaravel-1324103-4877027.cloudwaysapps.com/api/delete-cars-model/${modelId}`);
        toast.success('Model deleted successfully!');
        fetchModels(); // Refresh models list
      } catch (error) {
        toast.error('Error deleting model.');
      }
    };

    return (
      <Container className="mt-5 p-0" >
        <h1>Manage Car Models</h1>
        <Form>
          <Form.Group controlId="carSelect">
            <Form.Label>Select Car</Form.Label>
            <Form.Control as="select" value={selectedCar} onChange={e => setSelectedCar(e.target.value)}>
              <option value="">Select Car</option>
              {cars.map(car => (
                <option key={car.id} value={car.id}>{car.car_name}</option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="makerSelect">
            <Form.Label>Select Maker</Form.Label>
            <Form.Control as="select" value={selectedMaker} onChange={e => setSelectedMaker(e.target.value)}>
              <option value="">Select Maker</option>
              {makers.map(maker => (
                <option key={maker.id} value={maker.id}>{maker.maker_name}</option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="yearInput">
            <Form.Label>Model Name</Form.Label>
            <Form.Control type="text" value={year} onChange={e => setYear(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="carTypeInput">
            <Form.Label>Car Type</Form.Label>
            <Form.Control type="text" value={carType} onChange={e => setCarType(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="engineCapacityInput">
            <Form.Label>Engine Capacity</Form.Label>
            <Form.Control type="text" value={engineCapacity} onChange={e => setEngineCapacity(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="serviceTypeInput">
            <Form.Label>Service Type</Form.Label>
            <Form.Control type="text" value={serviceType} onChange={e => setServiceType(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="productDescInput">
            <Form.Label>Product Description</Form.Label>
            <Form.Control type="text" value={productDesc} onChange={e => setProductDesc(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="productCodeInput">
            <Form.Label>Product Code</Form.Label>
            <Form.Control type="text" value={productCode} onChange={e => setProductCode(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="headTitleInput">
            <Form.Label>Head Title</Form.Label>
            <Form.Control type="text" value={headTitle} onChange={e => setHeadTitle(e.target.value)} />
          </Form.Group>

          <Button className='mb-2 mt-2' variant="primary" onClick={handleAddModel}>{loading ? "Adding..." : "Add Model"}</Button>
        </Form>
        <Container style={{overflow:"scroll"}}>
          <h2 className='text-center text-secondary fw-bold'>Previous Data</h2>
        <Table striped bordered hover className="mt-4" >
          <thead>
            <tr style={{fontSize:"15px"}}>
              <th>Model_ID</th>
              <th>Year</th>
              <th>Car_Type</th>
              <th>Engine_Capacity</th>
              <th>Service_Type</th>
              <th>Product_desctription</th>
              <th>Product_Code</th>
              <th>Product_title</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {models.map(model => (
              <tr key={model.id} style={{fontSize:"14px"}}>
                <td>{model.id}</td>
                <td>{model.model_name}</td>
                <td>{model.car_type}</td>
                <td>{model.engine_capacity}</td>
                <td>{model.service_type}</td>
                <td>{model.product_desc}</td>
                <td>{model.product_code}</td>
                <td>{model.head_title}</td>
                <td className='d-flex '>
                  <Button className='me-1' variant="primary" onClick={() => handleOpenUpdateModal(model)}><i className="fa-solid fa-pen-to-square"></i></Button>
                  <Button variant="danger" onClick={() => handleDeleteModel(model.id)}><i className="fa-solid fa-trash"></i></Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        </Container>


        {/* Update Model Modal */}
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Update Model</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="newYearInput">
              <Form.Label>New Year</Form.Label>
              <Form.Control
                type="text"
                value={newYear}
                onChange={e => setNewYear(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="newCarTypeInput">
              <Form.Label>New Car Type</Form.Label>
              <Form.Control
                type="text"
                value={newCarType}
                onChange={e => setNewCarType(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="newEngineCapacityInput">
              <Form.Label>New Engine Capacity</Form.Label>
              <Form.Control
                type="text"
                value={newEngineCapacity}
                onChange={e => setNewEngineCapacity(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="newServiceTypeInput">
              <Form.Label>New product Description</Form.Label>
              <Form.Control
                type="text"
                value={newproductDesc}
                onChange={e => setNewProductDesc(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="newServiceTypeInput">
              <Form.Label>Product Code</Form.Label>
              <Form.Control
                type="text"
                value={newproductCode}
                onChange={e => setNewProductCode(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="newServiceTypeInput">
              <Form.Label>Head Title</Form.Label>
              <Form.Control
                type="text"
                value={newHeadTitle}
                onChange={e => setNewHeadTitle(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleUpdateModel}>
              {loading ? 'Saving Changes.... ' : 'Save Changes'}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  };

  export default CarModelManager;
