import React, { useState, useEffect, useContext } from "react";
import { StepContext } from "../../../Context/StepContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../../Components/Footer";
import AdminHeader from "../../../Components/AdminHeader";
import Sidebar from "../../../Components/Sidebar";

const CartData = () => {
  const { cart } = useContext(StepContext);

  return (
    <>
      <ToastContainer />
      <AdminHeader />
      <div className="container-fluid p-0">
        <div className="row">
          <Sidebar/>
          <div className="col-md-9">
          <div className=" mt-5">
          <h2 className="mb-4 text-center">Cart Data</h2>
          <div className="row">
            <div className="col-md-12">
              <table className="table table-striped">
                <thead>
                  <tr className="text-center">
                    <th>Booking_date</th>
                    <th>Cutomer_Name</th>
                    <th>Phone</th>
                    <th>Postcode</th>
                    <th>Car_Number</th>
                    <th>Model</th>
                    <th>Reg_date</th>
                    <th>MOT_Exp_date</th>
                  </tr>
                </thead>
                <tbody>
                  {cart.length > 0 ? (
                      cart.map((item, index) => (
                        <>
                          <tr key={index} className="text-center">
                            <td>{item.bookingDate}</td>
                            <td>
                              {item.fname} {item.lname}
                            </td>
                            <td>{item.phone}</td>
                            <td>{item.postcode}</td>
                            <td>{item.carNumber}</td>
                            <td>{item.Model}</td>
                            <td>{item.firstRegistrationDate}</td>
                            <td>{item.motExpiryDate}</td>
                          </tr>
                        </>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={10}>No data found</td>
                    </tr>
                  )}
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
          </div>
        </div>
       
      </div>
    </>
  );
};

export default CartData;
