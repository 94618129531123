import React, { useState, useEffect, useContext } from "react";
import {
    Card,
    Button,
    Container,
    Row,
    Col,
    Table,
    Modal,
    Form,
    Dropdown,
    DropdownButton,
    Spinner,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../Components/Footer";
import AdminHeader from "../../Components/AdminHeader";
import Sidebar from "../../Components/Sidebar";
import { collection, getDocs, doc, updateDoc, deleteDoc, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { StepContext } from "../../Context/StepContext"; // Import the context
import '../../Pages/admin/PendingOrders/PendingOrders.css';

export default function CancelledOrder() {
    const [orders, setOrders] = useState([]);
    const [currentOrder, setCurrentOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const { addPendingOrder } = useContext(StepContext); // Use the context to get addPendingOrder

    useEffect(() => {
        const fetchCancelledOrder = async () => {
            setLoading(true);
            try {
                const ordersQuery = query(collection(db, "Orders"), where("status", "==", "Cancelled"));
                const ordersSnapshot = await getDocs(ordersQuery);
                const fetchedOrders = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setOrders(fetchedOrders);
            } catch (error) {
                console.error("Error fetching cancelled orders: ", error);
                toast.error("Failed to fetch cancelled orders.");
            } finally {
                setLoading(false);
            }
        };

        fetchCancelledOrder();
    }, []);

    const handleShowDetails = (order) => {
        setCurrentOrder(order);
        setShowDetailsModal(true);
    };

    const handleShowEdit = (order) => {
        setCurrentOrder(order);
        setShowEditModal(true);
    };

    const handleShowStatus = (order) => {
        setCurrentOrder(order);
        setShowStatusModal(true);
    };

    const handleDeleteOrder = async (orderId) => {
        try {
            await deleteDoc(doc(db, "Orders", orderId));
            setOrders(orders.filter(order => order.id !== orderId));
        } catch (error) {
            console.error("Error deleting order: ", error);
        }
    };

    const handleEditChange = (field, value) => {
        setCurrentOrder((prevOrder) => ({
            ...prevOrder,
            userDetails: {
                ...prevOrder.userDetails,
                [field]: value,
            },
        }));
    };

    const handleSaveChanges = async () => {
        try {
            const orderRef = doc(db, "Orders", currentOrder.id);
            await updateDoc(orderRef, currentOrder);
            setOrders(orders.map(o => (o.id === currentOrder.id ? currentOrder : o)));
            setShowEditModal(false);
        } catch (error) {
            console.error("Error updating order: ", error);
        }
    };

    const handleStatusChange = async (status) => {
        const updatedOrder = { ...currentOrder, status: status };

        try {
            const orderRef = doc(db, "Orders", updatedOrder.id);
            await updateDoc(orderRef, updatedOrder);
            setOrders(orders.map(o => (o.id === updatedOrder.id ? updatedOrder : o)));
            setShowStatusModal(false);

            const pendingOrderRef = doc(db, "PendingOrders", updatedOrder.id);

            if (status === "Pending") {
                await updateDoc(pendingOrderRef, updatedOrder);
            } else {
                await deleteDoc(pendingOrderRef);
            }

        } catch (error) {
            console.error("Error updating status: ", error);
        }
    };

    return (
        <>
            <ToastContainer />
            <AdminHeader />
            <Container fluid className="p-0">
                <Row>
                    <Sidebar />
                    <Col md={9} className="main-content">
                        <div className="mt-5">
                            <h2 className="mb-4 text-center animated-title">Cancelled Orders</h2>
                            <Row>
                                <Col md={12}>
                                    {loading ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <Spinner animation="border" variant="primary" />
                                        </div>
                                    ) : orders.length > 0 ? (
                                        <div className="table-responsive">
                                            <Table striped bordered hover className="table-striped">
                                                <thead className="thead-dark">
                                                    <tr className="text-center">
                                                        <th>Booking Date</th>
                                                        <th>Customer Name</th>
                                                        <th>Phone</th>
                                                        <th>Postcode</th>
                                                        <th>Car Number</th>
                                                        <th>Registration Date</th>
                                                        <th>MOT Expiry Date</th>
                                                        <th>Total Price</th>
                                                        <th>Discount</th>
                                                        <th>Status</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orders.map((order, index) => (
                                                        <tr key={index} className="text-center animate__animated animate__fadeInUp">
                                                            <td>{order.date}</td>
                                                            <td>{order.userDetails?.name}</td>
                                                            <td>{order.userDetails?.phone}</td>
                                                            <td>{order.userDetails?.zip}</td>
                                                            <td>{order.userDetails?.carNumber}</td>
                                                            <td>{order.userDetails?.regDate}</td>
                                                            <td>{order.userDetails?.motExpDate}</td>
                                                            <td>${order.totalPrice}</td>
                                                            <td>${order.discount}</td>
                                                            <td>{order.status || "Pending"}</td>
                                                            <td>
                                                                <div
                                                                    style={{
                                                                        display: "grid",
                                                                        gridTemplateColumns: "1fr 1fr",
                                                                        gap: "10px",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        size="sm"
                                                                        variant="info"
                                                                        onClick={() => handleShowDetails(order)}
                                                                    >
                                                                        Details
                                                                    </Button>
                                                                    <Button
                                                                        size="sm"
                                                                        variant="warning"
                                                                        onClick={() => handleShowEdit(order)}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                    <Button
                                                                        size="sm"
                                                                        variant="primary"
                                                                        onClick={() => handleShowStatus(order)}
                                                                    >
                                                                        Status
                                                                    </Button>
                                                                    <Button
                                                                        size="sm"
                                                                        variant="danger"
                                                                        onClick={() => handleDeleteOrder(order.id)}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    ) : (
                                        <div className="empty-state text-center animate__animated animate__fadeInUp">
                                            <p>No cancelled orders found</p>
                                            <i className="fas fa-box-open fa-3x"></i>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />

            {/* Order Details Modal */}
            {currentOrder && (
                <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Order Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Customer Information</h5>
                        <p><strong>Name:</strong> {currentOrder.userDetails.name}</p>
                        <p><strong>Email:</strong> {currentOrder.userDetails.email}</p>
                        <p><strong>Phone:</strong> {currentOrder.userDetails.phone}</p>
                        <p><strong>Address:</strong> {currentOrder.userDetails.address}, {currentOrder.userDetails.state}, {currentOrder.userDetails.country}</p>
                        <p><strong>Postcode:</strong> {currentOrder.userDetails.zip}</p>

                        <h5>Car Information</h5>
                        <p><strong>Car Number:</strong> {currentOrder.userDetails.carNumber}</p>
                        <p><strong>Model:</strong> {currentOrder.userDetails.model}</p>
                        <p><strong>Reg Date:</strong> {currentOrder.userDetails.regDate}</p>
                        <p><strong>MOT Exp Date:</strong> {currentOrder.userDetails.motExpDate}</p>

                        <h5>Order Items</h5>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentOrder.cartItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>{item.plan.price}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.totalPrice}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Edit Order Modal */}
            {currentOrder && (
                <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formCustomerName" className="mb-3">
                                <Form.Label>Customer Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentOrder.userDetails.name}
                                    onChange={(e) => handleEditChange("name", e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formPhone" className="mb-3">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentOrder.userDetails.phone}
                                    onChange={(e) => handleEditChange("phone", e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formZip" className="mb-3">
                                <Form.Label>Postcode</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentOrder.userDetails.zip}
                                    onChange={(e) => handleEditChange("zip", e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formCarNumber" className="mb-3">
                                <Form.Label>Car Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentOrder.userDetails.carNumber}
                                    onChange={(e) => handleEditChange("carNumber", e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formModel" className="mb-3">
                                <Form.Label>Model</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentOrder.userDetails.model}
                                    onChange={(e) => handleEditChange("model", e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formRegDate" className="mb-3">
                                <Form.Label>Reg Date</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentOrder.userDetails.regDate}
                                    onChange={(e) => handleEditChange("regDate", e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formMotExpDate" className="mb-3">
                                <Form.Label>MOT Exp Date</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={currentOrder.userDetails.motExpDate}
                                    onChange={(e) => handleEditChange("motExpDate", e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSaveChanges}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Status Modal */}
            {currentOrder && (
                <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Order Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DropdownButton
                            id="dropdown-status"
                            title={currentOrder.status || "Pending"}
                            onSelect={handleStatusChange}
                        >
                            <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                            <Dropdown.Item eventKey="Delivered">Delivered</Dropdown.Item>
                            <Dropdown.Item eventKey="Cancelled">Cancelled</Dropdown.Item>
                        </DropdownButton>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowStatusModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

        </>
    );
}
