import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  Dropdown,
  DropdownButton,
  Carousel,
  Spinner,
} from "react-bootstrap";
import TopHeader from "../../Components/TopHeader";
import MainHeader from "../../Components/MainHeader";
import Footer from "../../Components/Footer";
import "../../Pages/ProductsDetails/ProductsDetails.css";
import { FaCartPlus } from "react-icons/fa";
import axios from "axios";

const ProductDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  console.log("Location State:", location.state);

  const { car_id, maker_id, model_id } = location.state || {};
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  console.log("selected car is and model id is", car_id, " + ", model_id)
  useEffect(() => {
    const fetchCarData = async () => {
      if (!car_id || !maker_id ) {
        setError("Car ID, Maker ID, and Model ID are required.");
        setLoading(false);
        return;
      }

      setLoading(true);
      setError("");
      try {
        // Fetch model details using car_id, maker_id, and model_id
        const modelResponse = await axios.get(
          "https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-models",
          {
            params: { car_id, maker_id, model_id },
          }
        );

        console.log("Model response data:", modelResponse.data);

        if (
          modelResponse.data &&
          Array.isArray(modelResponse.data.models) &&
          modelResponse.data.models.length > 0
        ) {
          const modelsWithDetails = await Promise.all(
            modelResponse.data.models.map(async (model) => {
              // Fetch images and videos for the specific model using POST request
              try {
                const imageResponse = await fetch(
                  "https://phplaravel-1324103-4877027.cloudwaysapps.com/api/get-car-img",
                  {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      car_id: car_id,
                      maker_id: maker_id,
                      model_id: model.id,
                    }),
                  }
                );

                if (!imageResponse.ok) {
                  console.error(`Failed to fetch images for model: ${model.id}`);
                  throw new Error("Failed to fetch images");
                }

                const imageData = await imageResponse.json();
                const images = imageData.all_images.map((img) => ({
                  ...img,
                  url: `${imageData.imagepath}${img.car_image}`,
                }));

                // Filter out null videos and map the rest
                const videos = imageData.all_videos
                  .filter((vid) => vid.car_video !== null)
                  .map((vid) => ({
                    ...vid,
                    url: `${imageData.videopath}${vid.car_video}`,
                  }));

                // Fetch prices for the specific model
                const priceResponse = await fetch(
                  `https://phplaravel-1324103-4877027.cloudwaysapps.com/api/get-car-model-price/${model.id}`
                );

                if (!priceResponse.ok) {
                  console.error(`Failed to fetch prices for model: ${model.id}`);
                  throw new Error("Failed to fetch prices");
                }

                const priceData = await priceResponse.json();

                return {
                  ...model,
                  images,
                  videos, // Add videos to the model object
                  plans: priceData.model_prices,
                  activePlan: null, // Add activePlan for each model
                };
              } catch (imageError) {
                console.error(`Error fetching images for model ${model.id}:`, imageError);
                return { ...model, images: [], videos: [], plans: [], activePlan: null };
              }
            })
          );

          setModels(modelsWithDetails);
        } else {
          console.warn("No models data available or incorrect format");
          throw new Error("No models data available");
        }
      } catch (error) {
        console.error("Error fetching car details:", error);
        setError(error.message || "Failed to fetch car details");
        setModels([]);
      } finally {
        setLoading(false);
      }
    };

    fetchCarData();
  }, [car_id, maker_id, model_id]);

  const handleSelectPlan = (modelId, selectedPlanId) => {
    const updatedModels = models.map((model) => {
      if (model.id === modelId) {
        const selectedPlan = model.plans.find(
          (plan) => plan.id.toString() === selectedPlanId
        );
        return { ...model, activePlan: selectedPlan };
      }
      return model;
    });
    setModels(updatedModels);
  };

  const addToCartAndNavigate = (model) => {
    if (model.activePlan) {
      const selectedCarData = {
        id: model.id,
        name: model.activePlan.plan_name,
        images: model.images,
        videos: model.videos, // Ensure videos are included
        plan: model.activePlan,
        quantity: 1,
        totalPrice: parseFloat(model.activePlan.price),
        productCode: model.product_code,
        productDescription: model.product_desc,
        headTitle: model.head_title,
        engineCapacity: model.engine_capacity || "N/A",
        modelName: model.model_name,
        carType: model.car_type || "N/A",
        serviceType: model.service_type || "N/A",
      };
      console.log("Car data sent to ProductSingle", selectedCarData);

      navigate("/Product-single", { state: { selectedCars: [selectedCarData] } });
    }
  };

  return (
    <div>
      <TopHeader />
      <MainHeader />
      <Container className="mt-4 product_details_main">
        {loading ? (
          <Spinner animation="border" role="status" className="d-block mx-auto">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : models.length > 0 ? (
          <>
            <Row className="mb-4">
              <Col>
                <h1>Car Details</h1>
              </Col>
            </Row>
            <Row>
              {models.map((model) => (
                <Col lg={4} md={6} sm={12} key={model.id} className="mb-4">
                  <Card className="image-card shadow h-100">
                    <Carousel
                      className="image-card-carousel"
                      interval={null}
                      style={{ height: "250px" }}
                    >
                      {model.images.map((img, idx) => (
                        <Carousel.Item key={`img-${idx}`}>
                          <img
                            src={img.url}
                            alt={`Model ${model.model_name} Image ${idx}`}
                            className="d-block w-100 h-100"
                          />
                        </Carousel.Item>
                      ))}
                      {model.videos.map((vid, idx) => (
                        <Carousel.Item key={`vid-${idx}`}>
                          <video
                            className="d-block w-100 h-100"
                            controls
                            src={vid.url}
                          >
                            Your browser does not support the video tag.
                          </video>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                    <Card.Body className="pb-0">
                      <Card.Title>
                        {model.model_name}
                        <h5 className="text-secondary" style={{ fontSize: "13px" }}>
                          {model.head_title || "head title"}
                        </h5>
                      </Card.Title>
                      <hr className="bg-secondary" />
                      <Card.Text className="px-3">
                        <ul
                          style={{ listStyle: "square" }}
                          className="mb-2 names d-flex justify-content-between p-0"
                        >
                          <li>
                            <strong>{model.service_type || "N/A"}</strong>
                          </li>
                          <li>
                            <strong>{model.engine_capacity || "N/A"}</strong>
                          </li>
                        </ul>
                        <ul
                          style={{ listStyle: "square" }}
                          className="mb-0 names d-flex justify-content-between p-0"
                        >
                          <li>{model.car_type || "not added"}</li>
                          <li>{model.product_code || "code"}</li>
                        </ul>
                      </Card.Text>
                      <hr className="bg-secondary" />
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-between align-items-center px-3 border-0">
                      <DropdownButton
                        id={`dropdown-${model.id}`}
                        title={
                          model.activePlan
                            ? `${model.activePlan.plan_duration} - $${model.activePlan.price}`
                            : "Select a Plan"
                        }
                        onSelect={(selectedPlanId) =>
                          handleSelectPlan(model.id, selectedPlanId)
                        }
                      >
                        {model.plans.map((plan) => (
                          <Dropdown.Item
                            key={plan.id}
                            eventKey={plan.id.toString()}
                          >
                            {`${plan.plan_duration} - $${plan.price}`}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                      <button
                        className="btn btn-info fw-bold"
                        onClick={() => addToCartAndNavigate(model)}
                      >
                        <FaCartPlus className="me-1" />
                        Buy Now
                      </button>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <Alert variant="danger">{error || "No car details available."}</Alert>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default ProductDetails;
