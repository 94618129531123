import React, { createContext, useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, setDoc, deleteDoc } from 'firebase/firestore'; // Import missing functions
import { db } from '../firebase';
import { useAuth } from './AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

export const StepContext = createContext();

export const StepProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    stepOneData: {},
    stepTwoData: {},
    stepThreeData: {},
    stepFourData: [],
    stepFiveData: {},
  });

  useEffect(() => {
    const fetchCartItems = async () => {
      setLoading(true);
      if (currentUser) {
        const cartItemsRef = collection(db, 'users', currentUser.uid, 'basketItems');
        const cartItemsSnapshot = await getDocs(cartItemsRef);
        const cartItems = cartItemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCart(cartItems);
      }
      setLoading(false);
    };

    fetchCartItems();
  }, [currentUser]);

  // Function to fetch pending orders
  const fetchPendingOrders = async () => {
    setLoading(true);
    try {
      const pendingOrdersRef = collection(db, 'Orders');
      const q = query(pendingOrdersRef, where('status', '==', 'Pending'));
      const querySnapshot = await getDocs(q);
      const pendingOrders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCart(pendingOrders); // Storing pending orders in cart
      console.log("Fetched Pending Orders: ", pendingOrders);
    } catch (error) {
      console.error("Error fetching pending orders: ", error);
      toast.error("Error fetching pending orders");
    } finally {
      setLoading(false);
      console.log("Loading state after fetching: ", loading);
    }
  };

  const updateFormData = (step, data) => {
    setFormData((prevData) => {
      if (step === 'stepFourData') {
        const updatedStepFourData = prevData.stepFourData.filter(row => row.rowId !== data.rowId);
        return {
          ...prevData,
          stepFourData: [...updatedStepFourData, data],
        };
      }
      return {
        ...prevData,
        [step]: data,
      };
    });
  };

  const addToCart = async (step, data) => {
    if (step === 'stepFourData') {
      const itemId = data.id || uuidv4();
      data.id = itemId;
      
      setCart((prevCart) => {
        const updatedCart = prevCart.filter(item => item.rowId !== data.rowId);
        return [...updatedCart, data];
      });

      if (currentUser) {
        const userBasketRef = doc(db, 'users', currentUser.uid, 'basketItems', itemId);
        await setDoc(userBasketRef, data);
      }
    } else {
      setCart((prevCart) => [...prevCart, formData[step]]);
    }
  };

  const removeItemFromCart = async (itemId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== itemId));

    if (currentUser) {
      const itemRef = doc(db, 'users', currentUser.uid, 'basketItems', itemId);
      await deleteDoc(itemRef);
    }
  };

  return (
    <StepContext.Provider value={{
      activeStep,
      setActiveStep,
      formData,
      updateFormData,
      cart,
      addToCart,
      removeItemFromCart,
      loading,
      fetchPendingOrders // Expose fetchPendingOrders function
    }}>
      {children}
    </StepContext.Provider>
  );
};
