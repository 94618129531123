import React from "react";
import CarForm from "../../../Components/CarForm";
import CarTable from "../../../Components/CarTable";
import AdminHeader from "../../../Components/AdminHeader";
import Footer from "../../../Components/Footer";
import { Container } from "react-bootstrap";
import Sidebar from "../../../Components/Sidebar";

function VehiclesData() {
  return (
    <>
      <AdminHeader />
      <div className="container-fluid p-0">
        <div className="row">
          <Sidebar />
          <div className="col-md-9">
            <Container className="mt-5">
              <h1 className="my-4 container text-center">Car Products</h1>
              <CarForm />
              <CarTable />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
export default VehiclesData;
